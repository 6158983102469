import { AlertTableColumn, ReportStatus } from '@/generated/enums'
import {
  useGetUserByIdQuery,
  useUpdateAppSettingsForUserMutation,
} from '@/generated/graphql'
import { useIsEmployeeView, useUserID } from '@/hooks/id_token_claims'
import { useMemo } from 'react'

// db value of app_settings is a json serialized version of this
// (possibly with a subset of keys, for backwards compatibility)
export type AppSettings = {
  default_report_preview_on: boolean
  default_report_status: ReportStatus
  alert_table_column_override: Partial<Record<AlertTableColumn, boolean>>
}

/**
 * hook for getting the user's current app settings
 * update func will only update the keys given in the input
 */
export const useAppSettings = (): {
  data: AppSettings | null
  loading: boolean
  update: (appSettingsUpdate: Partial<AppSettings>) => Promise<void>
} => {
  const [userID] = useUserID()
  const [isEmployeeView] = useIsEmployeeView()
  const { data: userInfo, loading: userInfoLoading } = useGetUserByIdQuery({
    variables: { id: userID },
  })
  const [updateAppSettingsForUserMutation] = useUpdateAppSettingsForUserMutation()

  // any key might be null in the database, but our abstraction will provide all of them
  const defaultAppSettings: AppSettings = useMemo(
    () => ({
      default_report_preview_on: false,
      default_report_status: isEmployeeView
        ? ReportStatus.NEEDS_REVIEW
        : ReportStatus.NEEDS_ACTION,
      alert_table_column_override: {},
    }),
    [isEmployeeView],
  )

  const currentAppSettings = useMemo(
    () => ({
      ...defaultAppSettings,
      ...(userInfo?.users?.[0]?.app_settings ?? {}),
    }),
    [defaultAppSettings, userInfo],
  )

  return {
    data: currentAppSettings,
    loading: userInfoLoading,
    update: async (appSettingsUpdate) => {
      await updateAppSettingsForUserMutation({
        variables: {
          user_id: userID,
          app_settings: {
            ...currentAppSettings,
            ...appSettingsUpdate,
          },
        },
      })
    },
  }
}
