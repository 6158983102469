import { SignedGCSObjectLink } from '@/components/shared/signed_gcs_object_link'
import TablePreview, { TablePreviewSize } from '@/components/tables/table_preview'
import { DetectionAssetType } from '@/generated/enums'
import { getBucketNameAndObjectName } from '@/utils/gcs_utils'
import { Text } from '@chakra-ui/react'

function DetectionAssetSignedGCSObjectLink({ url }) {
  const { bucketName, objectName } = getBucketNameAndObjectName(url)
  return (
    <SignedGCSObjectLink
      bucketName={bucketName}
      fileName={objectName}
      includeObject={false}
      linkDisplayName={'Link'}
      objectNamePrefix={''}
    />
  )
}

export function DetectionAssetAsset({
  detectionAsset,
  should_render_images = false,
  should_preview_strings = false,
}) {
  switch (detectionAsset.type) {
    case DetectionAssetType.REGEX_SITE_TEXT:
    case DetectionAssetType.REGEX_URL:
    case DetectionAssetType.REGEX_HTML:
      if (should_preview_strings && detectionAsset.value.length > 75) {
        return <Text>{detectionAsset.value.slice(0, 75)}...</Text>
      } else {
        return <Text>{detectionAsset.value}</Text>
      }

    case DetectionAssetType.SITE_FAVICON:
      if (should_render_images) {
        const faviconUrl = detectionAsset.value
        return (
          <TablePreview
            openOnClick
            previewSize={TablePreviewSize.LARGE}
            src={faviconUrl}
          />
        )
      } else {
        return <DetectionAssetSignedGCSObjectLink url={detectionAsset.value} />
      }
    case DetectionAssetType.SITE_SCREENSHOT:
    case DetectionAssetType.FULL_SITE_SCREENSHOT:
    case DetectionAssetType.MOBILE_SITE_SCREENSHOT:
    case DetectionAssetType.MOBILE_FULL_SITE_SCREENSHOT:
    case DetectionAssetType.BRAND_LOGO:
    case DetectionAssetType.SOCIAL_MEDIA_USER_PROFILE_IMAGE:
      if (should_render_images) {
        return (
          <TablePreview
            openOnClick
            previewSize={TablePreviewSize.LARGE}
            src={detectionAsset.value}
          />
        )
      } else {
        return <DetectionAssetSignedGCSObjectLink url={detectionAsset.value} />
      }

    case DetectionAssetType.SITE_HTML:
    case DetectionAssetType.SITE_TEXT:
    case DetectionAssetType.MOBILE_SITE_HTML:
    case DetectionAssetType.MOBILE_SITE_TEXT: {
      return <DetectionAssetSignedGCSObjectLink url={detectionAsset.value} />
    }

    default:
      if (should_preview_strings && detectionAsset.value.length > 75) {
        return <Text>{detectionAsset.value.slice(0, 75)}...</Text>
      } else {
        return <Text>{detectionAsset.value}</Text>
      }
  }
}
