import { Flex, Table, useColorModeValue } from '@chakra-ui/react'
import TableBody from './table_body'
import TableHeader from './table_header'
import TablePagination from './table_pagination'
import { DOPPEL_BLACK, DOPPEL_DARK, DOPPEL_WHITE } from '../../utils/style'
import { STANDARD_PAGE_SIZES } from '../../utils/constants'
import { ReactNode } from 'react'

const TableStyle = {
  padding: '12px 12px',
  textAlign: 'left',
  // width: '1px',
}

type PaginatedTableProps = {
  getTableProps: any
  getTableBodyProps: any
  headerGroups: any
  loading: boolean
  page: any
  totalPageCount: number
  prepareRow: any
  selectedRow: any
  setSelectedObj: any
  selectedPage: number
  setSelectedPage: any
  pageIndex: number
  pageSize: number
  setSelectedPageSize: any
  pageSizeOptionsOverride?: number[]
  postHeaderContent?: ReactNode
}

const PaginatedTable = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  loading,
  page,
  totalPageCount,
  prepareRow,
  selectedRow,
  setSelectedObj,
  selectedPage,
  setSelectedPage,
  pageIndex,
  pageSize,
  setSelectedPageSize,
  pageSizeOptionsOverride,
  postHeaderContent,
}: PaginatedTableProps) => {
  const bgColor = useColorModeValue(DOPPEL_WHITE, DOPPEL_DARK)
  const color = useColorModeValue(DOPPEL_BLACK, DOPPEL_WHITE)

  const pageSizeOptions = pageSizeOptionsOverride ?? STANDARD_PAGE_SIZES

  return (
    <>
      <Flex w="100%">
        <Table {...getTableProps()} bgColor={bgColor} color={color} width="100%">
          <TableHeader
            headerGroups={headerGroups}
            headerStyle={TableStyle}
            postHeaderContent={postHeaderContent}
          />

          <TableBody
            bodyStyle={TableStyle}
            isLoading={loading}
            page={page}
            prepareRow={prepareRow}
            selectedRow={selectedRow}
            setSelectedObj={setSelectedObj}
            tableBodyProps={getTableBodyProps}
          />
        </Table>
      </Flex>

      <TablePagination
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        setSelectedPageSize={setSelectedPageSize}
        totalPageCount={totalPageCount}
      />
    </>
  )
}

export default PaginatedTable
