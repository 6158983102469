import { useGcsSignedUrl } from '@/hooks/cloud_storage'
import { getFlaggedPreviewImage } from '@/utils/web2'
import { Td, Tooltip, Image } from '@chakra-ui/react'

export const HoverThumbnail = ({ alertId, screenshotUrl }) => {
  const { signedUrl, loading } = useGcsSignedUrl({ url: screenshotUrl })
  const fallbackImageUrl = getFlaggedPreviewImage({ id: alertId })

  return (
    <Td textAlign="left" p="0" pl="0">
      <Tooltip
        label={
          loading ? (
            <div>Loading...</div>
          ) : (
            <Image
              src={signedUrl || fallbackImageUrl}
              alt={`Preview for ${alertId}`}
              maxWidth="300px"
              maxHeight="300px"
              fallback={<div>No Preview</div>}
            />
          )
        }
        placement="auto"
        hasArrow
        bg="white"
        p="2"
        boxShadow="md"
        borderRadius="md"
        closeDelay={100}
      >
        <span style={{ cursor: 'pointer' }}>{alertId}</span>
      </Tooltip>
    </Td>
  )
}
