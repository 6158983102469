import {
  Checkbox,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Link,
  Text,
} from '@chakra-ui/react'
import React, { Key, useState } from 'react'
import { useUpdateSpoofingReportsMutation } from '@/generated/graphql'
import DoppelModal from '../detailed_view/doppel_modal'
import { TAG_NAME_TO_ID } from '@/utils/constants'
import { ReportChangeEventSource, ReportStatus, TagActionType } from '@/generated/enums'
import { ENFORCEMENT_TYPE_TAGS_BY_PRODUCT } from '@/utils/enforcement_utils'
import { getFlaggedPreviewImage } from '@/utils/web2'
import { HoverThumbnail } from '../shared/hover_thumbnail'
import { DOPPEL_DARK_SECONDARY } from '@/utils/style'
import { getExternalReportId } from '@/utils/reports/report_utils'

export default function BulkTagAlertsModal({ isOpen, onClose, selectedRows = [] }) {
  const toast = useToast()
  const [selectedTags, setSelectedTags] = useState({})
  const maxAlertsAllowed = 10
  const limitedSelectedRows = selectedRows.slice(0, maxAlertsAllowed)

  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation()

  const updateSelectedTags = (alertId, tag, isSelected) => {
    setSelectedTags((prev) => {
      const updatedTags = { ...prev }
      if (!updatedTags[alertId]) {
        updatedTags[alertId] = []
      }
      const existingTag = updatedTags[alertId].find((t) => t.tag === tag)
      if (existingTag) {
        existingTag.isSelected = isSelected
      } else {
        updatedTags[alertId].push({ tag, isSelected: isSelected ?? false })
      }
      return updatedTags
    })
  }

  const handleUpdatingTags = async () => {
    for (const alert of limitedSelectedRows) {
      const alertId = alert.original.id
      const tags =
        ENFORCEMENT_TYPE_TAGS_BY_PRODUCT[alert.original.platform_subtype.product] ||
        ENFORCEMENT_TYPE_TAGS_BY_PRODUCT.default
      for (const tag of tags) {
        const tagObject = selectedTags[alertId]?.find((t) => t.tag === tag)
        if (tagObject && tagObject.isSelected) {
          const tagId = TAG_NAME_TO_ID[tag]
          await updateSpoofingReportsMutation({
            variables: {
              input: {
                report_ids: [alertId],
                update_source: ReportChangeEventSource.BULK_UI,
                report_status: ReportStatus.REPORTED,
                tag_id: tagId,
                tag_action: TagActionType.ADD,
              },
            },
          })
        }
      }
    }
  }

  const validateSubmission = () => {
    return limitedSelectedRows.every((row) => {
      const alertId = row.original.id
      const tags =
        ENFORCEMENT_TYPE_TAGS_BY_PRODUCT[row.original.platform_subtype.product] ||
        ENFORCEMENT_TYPE_TAGS_BY_PRODUCT.default
      return tags.some(
        (tag) => selectedTags[alertId]?.find((t) => t.tag === tag)?.isSelected ?? false,
      )
    })
  }

  const handleSubmit = async () => {
    if (!validateSubmission()) {
      toast({
        title: 'Submission Error',
        description: 'All alerts must have at least one tag selected.',
        status: 'error',
        isClosable: true,
      })
      return
    }
    try {
      await handleUpdatingTags()
      toast({
        title: 'Alerts updated successfully',
        status: 'success',
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        title: 'Error tagging alerts',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
    }
  }

  const bulkTagModalDescription = () => {
    const numberAlertsText =
      'This modal allows tagging and auto-actioning on selected alerts. Ensure that all alerts have the correct tags and should be reported.'
    const warningMessageText =
      'Warning: Clicking Submit will tag and auto-action all selected alerts. Please double-check that the tags are correct before proceeding.'
    return (
      <>
        <Text color="red" fontSize="sm">
          {numberAlertsText}
        </Text>

        <Text color="red" fontSize="sm" fontWeight="bold">
          {warningMessageText}
        </Text>
      </>
    )
  }

  const renderModalBody = () => {
    const type =
      limitedSelectedRows[0]?.original.platform_subtype.product ||
      limitedSelectedRows[0]?.original.platform.product

    const tagsByAlertType = !(type in ENFORCEMENT_TYPE_TAGS_BY_PRODUCT)
      ? ENFORCEMENT_TYPE_TAGS_BY_PRODUCT.default
      : ENFORCEMENT_TYPE_TAGS_BY_PRODUCT[type]
    const tagColumns = Object.values(tagsByAlertType) as Key[]

    return (
      <Stack spacing="24px">
        <Stack spacing="8px">
          <Text fontSize="md" fontWeight="bold">
            Number of alerts selected: {limitedSelectedRows.length}
          </Text>

          {bulkTagModalDescription()}
        </Stack>

        <div
          style={{
            maxHeight: '400px',
            overflowY: 'auto',
          }}
        >
          <Table size="md" variant="simple">
            <Thead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: DOPPEL_DARK_SECONDARY,
              }}
            >
              <Tr>
                <Th>External ID</Th>

                <Th>URL</Th>

                {tagColumns.map((tag) => (
                  <Th key={tag} textAlign="center">
                    {tag}
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {limitedSelectedRows.map((row) => {
                const alertId = row.original.id
                const alertExternalId = getExternalReportId(
                  row.original.organization.abbr_name,
                  row.original.external_id,
                )
                const alertUrl = row.original.flagged_url
                const thumbnailUrl = getFlaggedPreviewImage(row.original)

                return (
                  <Tr key={alertId}>
                    <Td whiteSpace="nowrap">
                      <HoverThumbnail
                        alertId={alertExternalId}
                        screenshotUrl={thumbnailUrl}
                      />
                    </Td>

                    <Td wordBreak="break-word">
                      <Link
                        color="blue.500"
                        href={`https://app.doppel.com/alerts/${alertExternalId}`}
                        isExternal
                      >
                        {alertUrl}
                      </Link>
                    </Td>

                    {tagColumns.map((tag) => (
                      <Td key={tag} textAlign="center">
                        <Checkbox
                          isChecked={
                            selectedTags[alertId]?.find((t) => t.tag === tag)
                              ?.isSelected || false
                          }
                          onChange={(e) =>
                            updateSelectedTags(alertId, tag, e.target.checked)
                          }
                        />
                      </Td>
                    ))}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </div>
      </Stack>
    )
  }

  return (
    <DoppelModal
      body={renderModalBody()}
      isAsync={true}
      isOpen={isOpen}
      onClose={onClose}
      primaryAction={handleSubmit}
      primaryLabel="Submit"
      size="6xl"
      title={`Add Tags To Selected Alerts Before Moving to Actioned`}
    />
  )
}
