import {
  DomainPlatform,
  EmailPlatform,
  ENFORCEMENT_STATUS,
  ENFORCEMENT_TYPE,
  EnforcementPlatform,
  ProductType,
  SUPPORTED_AUTO_FORM_ENFORCEMENT_OPTIONS,
  SUPPORTED_API_ENFORCEMENT_OPTIONS,
  SocialMediaPlatform,
  ZERO_UUID,
  ALERT_DETAILED_VIEW_TIMESTAMP_FORMAT,
} from './constants'
import { FaGhost, FaGoogle, FaPen, FaCoins, FaCloud, FaTelegram } from 'react-icons/fa'
import {
  VStack,
  FormControl,
  Link,
  Image,
  Spacer,
  Box,
  FormLabel,
  Text,
  ListItem,
  List,
  Tooltip,
  Stack,
} from '@chakra-ui/react'
import { GiFox } from 'react-icons/gi'
import { EnforcementFormInput } from '../components/enforcements/internal_enforcement_form'
import {
  CustomerType,
  AutoStagingDisabledMultiplePlatforms,
  AutoStagingDisabledReason,
  AutoStagingDisabledParamName,
  PageRoute,
  SubmissionSource,
  EnforcementTypeReportTag,
} from '../generated/enums'
import url, { UrlWithStringQuery } from 'url'
import DoppelFormLabel from '../components/shared/forms/doppel_form_label'
import TextInputField from '../components/shared/text_input_field'
import {
  DOPPEL_BREACH_RED,
  DOPPEL_CLOUD_BLUE_SHADE,
  DOPPEL_ENCRYPT_GREEN_SHADE,
  DOPPEL_FIREWALL_ORANGE,
} from './style'
import FileDropzone from '../components/shared/file_dropzone'
import { containsIPAddress } from './domain_utils'
import { getExternalUser } from './reports/report_utils'
import { getTimestampDisplay } from './time'

export const PHISHING_DOMAINS_ENFORCEMENT_PLATFORMS: EnforcementPlatform[] = [
  DomainPlatform.NAMECHEAP,
  DomainPlatform.NAMESILO,
  DomainPlatform.TUCOWS,
  DomainPlatform.PORKBUN,
  DomainPlatform.CLEANDNS,
  DomainPlatform.PUBLIC_DOMAIN_REGISTRY,
  DomainPlatform.HOSTINGER,
  DomainPlatform.SAV,
  DomainPlatform.GNAME,
  DomainPlatform.DYNADOT,
  DomainPlatform.NICENIC,
  DomainPlatform.SAREK_OY,
  DomainPlatform.EPIK,
  DomainPlatform.GODADDY,
  DomainPlatform.CLOUDFLARE,
  DomainPlatform.GOOGLE,
  DomainPlatform.CUSTOM,
]

export const EMAIL_ENFORCEMENT_PLATFORMS: EnforcementPlatform[] = [
  EmailPlatform.GMAIL,
  EmailPlatform.YAHOO,
  EmailPlatform.PROTONMAIL,
  EmailPlatform.OUTLOOK,
  EmailPlatform.ZOHO,
  EmailPlatform.PRIVATEEMAIL,
  EmailPlatform.ICLOUD,
  EmailPlatform.TEMP_EMAIL,
]

// Products for which suggested enforcements is enabled but platform is already known from report
export const KNOWN_PLATFORM_SUGGESTED_ENFORCEMENT_PRODUCTS = [
  ProductType.MOBILE_APPS,
  ProductType.SOCIAL_MEDIA,
]

export const CLEANDNS_NON_AUTOSTAGE_HOST_ENFORCEMENT_PLATFORMS: EnforcementPlatform[] =
  [DomainPlatform.DYNADOT]

export const CLEANDNS_NON_AUTOSTAGE_REGISTRAR_ENFORCEMENT_PLATFORMS: EnforcementPlatform[] =
  [DomainPlatform.CLOUDFLARE, DomainPlatform.DYNADOT, DomainPlatform.GNAME]

export const CRYPTO_STAGING_REQUIRED_PLATFORMS: EnforcementPlatform[] = [
  DomainPlatform.METAMASK,
  DomainPlatform.COINBASE,
  DomainPlatform.PHANTOM,
]

export const STAGING_ONLY_PLATFORMS = [SocialMediaPlatform.TELEGRAM.toLowerCase()]

export const ENFORCEMENT_STAGED_PLATFORMS: EnforcementPlatform[] = [
  SocialMediaPlatform.TELEGRAM,
]

export const ENFORCEMENT_STAGED_PRODUCTS: ProductType[] = [ProductType.MOBILE_APPS]

export const ENFORCEMENT_STAGED_SOURCES: SubmissionSource[] = [SubmissionSource.MANUAL]

// List of platforms for which generate auto-email is disabled if they are selected as the enforcement platform
export const AUTO_EMAIL_DISABLED_ENFORCEMENT_PLATFORMS: EnforcementPlatform[] = [
  DomainPlatform.NAMESILO,
]

// List of platforms for which generate auto-email is disabled if they are detected as the host/registrar
export const AUTO_EMAIL_DISABLED_HOST_REGISTRAR_PLATFORMS: EnforcementPlatform[] = [
  DomainPlatform.NAMESILO,
  DomainPlatform.CLOUDFLARE,
  DomainPlatform.NAMECHEAP,
]

export const BUSINESS_SUFFIXES = [
  'llc',
  'inc',
  'corp',
  'llp',
  'ltd',
  'plc',
  'gmbh',
  'sarl',
  'sa',
  'pte',
  'pty',
  'ag',
  'bv',
  'nv',
  'se',
  'sas',
  'srl',
  'spa',
  'sl',
  'ouy',
  'oy',
  'oyj',
  'ab',
  'as',
  'sa',
  'sar',
  'sc',
  'srl',
]

export const SOCIAL_MEDIA_ARCHETYPE_PREFIX = 'social_media_'

export const DEFAULT_SOCIAL_MEDIA_ARCHETYPE = 'social_media'

export const getIconForEnforcementPlatform = (platform) => {
  switch (platform) {
    case DomainPlatform.GOOGLE:
      return <FaGoogle />
    case DomainPlatform.METAMASK:
      return <GiFox />
    case DomainPlatform.PHANTOM:
      return <FaGhost />
    case DomainPlatform.COINBASE:
      return <FaCoins />
    case DomainPlatform.CLOUDFLARE:
      return <FaCloud />
    case SocialMediaPlatform.TELEGRAM.toLowerCase():
      return <FaTelegram />
    default:
      return <FaPen />
  }
}

export const PRODUCT_TYPE_TO_ENFORCEMENT_TYPE = {
  [ProductType.NFT]: [
    ENFORCEMENT_TYPE.PHISHING,
    ENFORCEMENT_TYPE.TRADEMARK,
    ENFORCEMENT_TYPE.COPYRIGHT,
    ENFORCEMENT_TYPE.PRODUCT_MISUSE,
    ENFORCEMENT_TYPE.UNDERAGE_USE,
  ],
  [ProductType.DOMAIN]: [
    ENFORCEMENT_TYPE.PHISHING,
    ENFORCEMENT_TYPE.TRADEMARK,
    ENFORCEMENT_TYPE.COPYRIGHT,
  ],
  [ProductType.SOCIAL_MEDIA]: [
    ENFORCEMENT_TYPE.IMPERSONATION,
    ENFORCEMENT_TYPE.PRODUCT_MISUSE,
    ENFORCEMENT_TYPE.TRADEMARK,
    ENFORCEMENT_TYPE.COPYRIGHT,
    ENFORCEMENT_TYPE.COUNTERFEIT,
  ],
  [ProductType.ECOMMERCE]: [
    ENFORCEMENT_TYPE.TRADEMARK,
    ENFORCEMENT_TYPE.COPYRIGHT,
    ENFORCEMENT_TYPE.COUNTERFEIT,
  ],
  [ProductType.MOBILE_APPS]: [ENFORCEMENT_TYPE.TRADEMARK, ENFORCEMENT_TYPE.DMCA],
  [ProductType.EMAIL]: [ENFORCEMENT_TYPE.PHISHING],
  [ProductType.DARK_MARKET]: [
    ENFORCEMENT_TYPE.TRADEMARK,
    ENFORCEMENT_TYPE.PRODUCT_MISUSE,
  ],
  [ProductType.PAID_ADS]: [ENFORCEMENT_TYPE.PHISHING, ENFORCEMENT_TYPE.TRADEMARK],
  [ProductType.CRYPTO]: [
    ENFORCEMENT_TYPE.PHISHING,
    ENFORCEMENT_TYPE.TRADEMARK,
    ENFORCEMENT_TYPE.COPYRIGHT,
  ],
  [ProductType.METAVERSE]: [],
  [ProductType.DARK_WEB]: [],
  [ProductType.TELCO]: [ENFORCEMENT_TYPE.PHISHING, ENFORCEMENT_TYPE.IMPERSONATION],
}

export enum EnforcementMethod {
  MANUAL = 'MANUAL',
  AUTO_FORM = 'AUTO_FORM',
  AUTO_API = 'AUTO_API',
}

export type EnforcementMethodData = {
  method: string
  formLink?: string
  email?: string
  apiLink?: string
}

export type AutoStagingDisabledPlatform =
  | AutoStagingDisabledMultiplePlatforms
  | DomainPlatform
export type AutoStagingDisabledParams = Record<string, string>

export type DisabledEnforcement = {
  platform: AutoStagingDisabledPlatform
  reason: AutoStagingDisabledReason
  params?: AutoStagingDisabledParams
}

export type AutoStagingEnforcements = {
  enforcements: EnforcementFormInput[]
  disabledEnforcements: DisabledEnforcement[]
}

export type SuggestedAutoStagedEnforcements = {
  autoStagedEnforcements: []
  disabledEnforcements: DisabledEnforcement[]
  needReviewEnforcements: []
}

export const getDefaultEnforcementFormInputs = (
  enforcementType,
  productType,
  spoofReport,
  customerType,
  enforcementMetadata,
  platformsWhereFlaggedUrlData = null,
  platformsWhereRootOrHostData = null,
): AutoStagingEnforcements => {
  const disableAllEnforcements = (
    reason: AutoStagingDisabledReason,
    params: AutoStagingDisabledParams = {},
  ): AutoStagingEnforcements => {
    return {
      enforcements: [],
      disabledEnforcements: [
        {
          platform: AutoStagingDisabledMultiplePlatforms.ALL,
          reason,
          params,
        },
      ],
    }
  }

  if (productType !== ProductType.DOMAIN) {
    return disableAllEnforcements(AutoStagingDisabledReason.PRODUCT_TYPE_NOT_DOMAIN, {
      [AutoStagingDisabledParamName.PRODUCT_TYPE]: productType,
    })
  } else if (enforcementType !== ENFORCEMENT_TYPE.PHISHING) {
    return disableAllEnforcements(
      AutoStagingDisabledReason.ENFORCEMENT_TYPE_NOT_PHISHING,
      { [AutoStagingDisabledParamName.ENFORCEMENT_TYPE]: enforcementType },
    )
  }
  const flaggedUrl = spoofReport?.flagged_url

  const isPathUrlData = isPathUrl(flaggedUrl)

  if (Object.keys(isPathUrlData).length > 0) {
    return disableAllEnforcements(
      AutoStagingDisabledReason.FLAGGED_URL_HAS_PATH,
      isPathUrlData,
    )
  }

  const isExactPlatformUrlData = isExactPlatformUrl(
    flaggedUrl,
    platformsWhereFlaggedUrlData,
  )

  if (Object.keys(isExactPlatformUrlData).length > 0) {
    return disableAllEnforcements(
      AutoStagingDisabledReason.FLAGGED_URL_HAS_EXACT_PLATFORM,
      isExactPlatformUrlData,
    )
  }

  const enforcementInputs = []
  const disabledEnforcements = []

  const disableSingleEnforcement = (
    platform: AutoStagingDisabledPlatform,
    reason: AutoStagingDisabledReason,
    params: AutoStagingDisabledParams = {},
  ) => {
    disabledEnforcements.push({
      platform,
      reason,
      params,
    })
  }

  // add google enforcement for all domains phishing reports
  enforcementInputs.push({
    platform: DomainPlatform.GOOGLE,
    type: ENFORCEMENT_TYPE.PHISHING,
    file: null,
    userInput: {},
    enforcementMethod: EnforcementMethod.AUTO_API,
  })

  const urlHasPlatformData = urlHasPlatform(flaggedUrl, platformsWhereRootOrHostData)
  const isInHostOrRegistrarData = isInHostOrRegistrar(
    spoofReport,
    CLEANDNS_NON_AUTOSTAGE_REGISTRAR_ENFORCEMENT_PLATFORMS,
    CLEANDNS_NON_AUTOSTAGE_HOST_ENFORCEMENT_PLATFORMS,
  )

  if (!enforcementMetadata?.legal_name) {
    disableSingleEnforcement(
      DomainPlatform.CLEANDNS,
      AutoStagingDisabledReason.ENFORCMENT_METADATA_NO_LEGAL_NAME,
    )
  } else if (Object.keys(urlHasPlatformData).length > 0) {
    disableSingleEnforcement(
      DomainPlatform.CLEANDNS,
      AutoStagingDisabledReason.FLAGGED_URL_HAS_PLATFORM,
      urlHasPlatformData,
    )
  } else if (Object.keys(isInHostOrRegistrarData).length > 0) {
    disableSingleEnforcement(
      DomainPlatform.CLEANDNS,
      AutoStagingDisabledReason.DISABLED_HOST_OR_REGISTRAR,
      isInHostOrRegistrarData,
    )
  } else {
    // add cleandns as long as legal name is filled out, flagged_url's host and root domain are not in platforms table, and host/registrar of flagged_url is not in the disabled list
    enforcementInputs.push({
      platform: DomainPlatform.CLEANDNS,
      type: ENFORCEMENT_TYPE.PHISHING,
      file: null,
      userInput: {},
      enforcementMethod: EnforcementMethod.AUTO_API,
    })
  }

  // add crypto enforcements only for crypto customers
  if (customerType !== CustomerType.CRYPTO) {
    disableSingleEnforcement(
      AutoStagingDisabledMultiplePlatforms.ALL_CRYPTO,
      AutoStagingDisabledReason.NOT_CRYPTO_CUSTOMER,
      { [AutoStagingDisabledParamName.CUSTOMER_TYPE]: customerType },
    )
  } else if (containsIPAddress(flaggedUrl)) {
    disableSingleEnforcement(
      AutoStagingDisabledMultiplePlatforms.ALL_CRYPTO,
      AutoStagingDisabledReason.IP_ADDRESS_FOUND,
      { [AutoStagingDisabledParamName.FLAGGED_URL]: flaggedUrl },
    )
  } else {
    enforcementInputs.push(
      ...CRYPTO_STAGING_REQUIRED_PLATFORMS.map((platform) => {
        return {
          platform,
          type: ENFORCEMENT_TYPE.PHISHING,
          file: null,
          userInput: {},
          enforcementMethod: getEnforcementMethod(
            false,
            platform,
            ENFORCEMENT_TYPE.PHISHING,
          ),
        }
      }),
    )
  }

  // filter out enforcements that are already "active" (not blocked or rejected)
  const filteredEnforcements = filterEnforcements(
    enforcementInputs,
    spoofReport,
    (enforcement) => {
      disableSingleEnforcement(
        enforcement.platform,
        AutoStagingDisabledReason.PLATFORM_ENFORCEMENT_ALREADY_ACTIVE,
      )
    },
  )

  return {
    enforcements: filteredEnforcements,
    disabledEnforcements: disabledEnforcements,
  }
}

export const filterEnforcements = (
  enforcements,
  spoofReport,
  actionToPerformForDisabledEnforcement,
  enforcementsToFilterOut?,
  actionToPerformForFilteredOutEnforcement?,
) => {
  const filteredEnforcements = enforcements.filter((enforcement) => {
    let isStagable = isAutoEnforcementStagable(
      spoofReport,
      enforcement.platform,
      enforcement.type,
    )
    if (!isStagable) {
      actionToPerformForDisabledEnforcement(enforcement)
    }
    if (
      isStagable &&
      enforcementsToFilterOut &&
      enforcementsToFilterOut.some(
        (existingEnforcement) =>
          existingEnforcement.platform === enforcement.platform &&
          existingEnforcement.type === enforcement.type,
      )
    ) {
      actionToPerformForFilteredOutEnforcement(enforcement)
      isStagable = false
    }
    return isStagable
  })
  return filteredEnforcements
}

const getParsedUrl = (flaggedUrl: string): UrlWithStringQuery => {
  let parsedUrl
  if (!flaggedUrl.startsWith('http://') && !flaggedUrl.startsWith('https://')) {
    parsedUrl = url.parse(`http://${flaggedUrl}`)
  } else {
    parsedUrl = url.parse(flaggedUrl)
  }
  return parsedUrl
}

export const isPathUrl = (flaggedUrl: string): AutoStagingDisabledParams => {
  const parsedUrl = getParsedUrl(flaggedUrl)
  // Ensure url is not a root path
  if (
    parsedUrl.pathname !== '/' ||
    parsedUrl.hash ||
    (parsedUrl.path != '' && parsedUrl.path != '/')
  ) {
    return {
      [AutoStagingDisabledParamName.FLAGGED_URL]: flaggedUrl,
      [AutoStagingDisabledParamName.DETECTED_PATH]: parsedUrl.pathname ?? '',
      [AutoStagingDisabledParamName.HOST_DOMAIN]:
        parsedUrl.host ?? parsedUrl.hostname ?? '',
    }
  }
  return {}
}

export const getHost = (flaggedUrl: string): string => {
  const parsedUrl = getParsedUrl(flaggedUrl)
  return parsedUrl.host ?? ''
}

export const getRootAndHost = (flaggedUrl: string): { root: string; host: string } => {
  if (!flaggedUrl) {
    return { root: '', host: '' }
  }
  const host = getHost(flaggedUrl)
  // Get the root domain by taking the last two parts of the host, e.g. 'subdomain.google.com' -> 'google.com'
  const hostParts = host ? host.split('.') : []
  const rootDomain = hostParts.length > 1 ? hostParts.slice(-2).join('.') : host
  return { root: rootDomain, host: host }
}

export const cleanRegistrarName = (registrarName: string): string => {
  let name = registrarName
  // Convert to lowercase
  name = name.toLowerCase()
  // Remove all square brackets and parentheses and everything in them
  name = name.replace(/(\[.*?\]|\(.*?\))/g, '')
  // Replace all commas with spaces
  name = name.replace(/,/g, ' ')
  // Remove all business suffixes
  name = name.replace(new RegExp(`(${BUSINESS_SUFFIXES.join('|')})\\b\\.?`, 'g'), '')
  // Remove all non-alphanumeric characters except spaces, slashes, and periods
  name = name.replace(/[^a-zA-Z0-9\s./]/g, '')
  // Replace multiple spaces with a single space
  name = name.replace(/\s+/g, ' ')
  // Trim leading and trailing spaces before removing trailing periods
  name = name.trim()
  // Remove trailing periods
  name = name.replace(/\.$/, '')
  // Trim leading and trailing spaces
  name = name.trim()
  return name
}

export const cleanHostingProvider = (hostingProvider: string): string => {
  const { root, host } = getRootAndHost(hostingProvider)
  const rootParts = root ? root.split('.') : []
  return rootParts.length > 0 ? rootParts[0] : root
}

export const isExactPlatformUrl = (
  flaggedUrl: string,
  platformsWhereFlaggedUrlData,
): AutoStagingDisabledParams => {
  // Checking if url is exactly a platform domain for a platform in platforms table
  if (platformsWhereFlaggedUrlData && platformsWhereFlaggedUrlData.length > 0) {
    return {
      [AutoStagingDisabledParamName.FLAGGED_URL]: flaggedUrl,
      [AutoStagingDisabledParamName.MATCHING_PLATFORM]:
        platformsWhereFlaggedUrlData[0].name,
    }
  }
  return {}
}

export const urlHasPlatform = (
  flaggedUrl: string,
  platformsWhereRootOrHostData,
): AutoStagingDisabledParams => {
  // Checking if the root or host of flagged url is a platform domain (host includes subdomain as well)
  if (platformsWhereRootOrHostData && platformsWhereRootOrHostData.length > 0) {
    return {
      [AutoStagingDisabledParamName.FLAGGED_URL]: flaggedUrl,
      [AutoStagingDisabledParamName.MATCHING_PLATFORM]:
        platformsWhereRootOrHostData[0].name,
    }
  }
  return {}
}

export const isInHostOrRegistrar = (
  spoofReport,
  registrarPlatforms,
  hostPlatforms,
): AutoStagingDisabledParams => {
  const registrarName =
    (spoofReport?.platform?.product === ProductType.DOMAIN &&
      spoofReport?.first_domain_data?.[0]?.full_url?.domain_data?.registrar_name?.toLowerCase()) ||
    null

  let matchingPlatform
  if (
    registrarName &&
    registrarPlatforms.some((platform) => {
      if (registrarName.includes(platform.toLowerCase())) {
        matchingPlatform = platform
        return true
      }
      return false
    })
  ) {
    return {
      [AutoStagingDisabledParamName.REGISTRAR_NAME]: registrarName,
      [AutoStagingDisabledParamName.MATCHING_PLATFORM]: matchingPlatform,
    }
  }

  const hostingProvider =
    (spoofReport?.platform?.product === ProductType.DOMAIN &&
      spoofReport?.first_domain_data?.[0]?.full_url?.domain_data?.hosting_provider?.toLowerCase()) ||
    null

  if (
    hostingProvider &&
    hostPlatforms.some((platform) => {
      if (hostingProvider.includes(platform.toLowerCase())) {
        matchingPlatform = platform
        return true
      }
      return false
    })
  ) {
    return {
      [AutoStagingDisabledParamName.HOSTING_PROVIDER]: hostingProvider,
      [AutoStagingDisabledParamName.MATCHING_PLATFORM]: matchingPlatform,
    }
  }

  return {}
}

export const getEnforcementPlatformOptions = (
  productType,
  source: SocialMediaPlatform,
): EnforcementPlatform[] => {
  // only can report straight to platform
  if (
    productType === ProductType.SOCIAL_MEDIA ||
    productType === ProductType.ECOMMERCE ||
    productType === ProductType.DARK_MARKET ||
    productType === ProductType.CRYPTO ||
    productType === ProductType.PAID_ADS ||
    productType === ProductType.TELCO
  ) {
    return [source]
  } else if (productType === ProductType.MOBILE_APPS) {
    return [source, DomainPlatform.CUSTOM]
  }

  if (productType === ProductType.DOMAIN) {
    return PHISHING_DOMAINS_ENFORCEMENT_PLATFORMS
  }

  if (productType === ProductType.EMAIL) {
    return EMAIL_ENFORCEMENT_PLATFORMS.concat(PHISHING_DOMAINS_ENFORCEMENT_PLATFORMS)
  }

  return []
}

export const getEnforcementMethod = (
  isAutoEmailEnforcementEnabled: boolean,
  platform: string,
  type: string,
) => {
  if (isAutoEmailEnforcementEnabled) {
    return EnforcementMethod.AUTO_API
  } else if (
    SUPPORTED_AUTO_FORM_ENFORCEMENT_OPTIONS.some(
      (option) => option[0] === platform && option[1] === type,
    )
  ) {
    return EnforcementMethod.AUTO_FORM
  } else if (
    SUPPORTED_API_ENFORCEMENT_OPTIONS.some(
      (option) => option[0] === platform && option[1] === type,
    )
  ) {
    return EnforcementMethod.AUTO_API
  }

  return EnforcementMethod.MANUAL
}

export const isSocialMediaPlatformMeta = (platform: string) => {
  return (
    platform === SocialMediaPlatform.INSTAGRAM.toLowerCase() ||
    platform === SocialMediaPlatform.FACEBOOK.toLowerCase()
  )
}

export const isAutoEnforcementStagable = (spoofReport, platform, type) => {
  // check to see if spoof report has any active enforcements for the same platform and type
  return spoofReport?.enforcement_requests?.every((enforcement) => {
    const isDifferentPlatform = enforcement.platform !== platform
    const isDifferentType = enforcement.type !== type
    const isBlockedOrRejected = [
      ENFORCEMENT_STATUS.BLOCKED,
      ENFORCEMENT_STATUS.REJECTED,
    ].includes(enforcement.status)
    const isGoogleEnforcement = platform == DomainPlatform.GOOGLE

    // If any Google enforcement has been staged previously for this report, don't allow resubmission
    const canResubmit = isBlockedOrRejected && !isGoogleEnforcement

    return isDifferentPlatform || isDifferentType || canResubmit
  })
}

export const isLastActionOlderThanCutoff = (lastActionedAt: Date, cutoff: number) => {
  const currentDate = new Date()
  const diff = currentDate.getTime() - lastActionedAt.getTime()
  const diffDays = Math.floor(diff / (1000 * 3600 * 24))
  return diffDays >= cutoff
}

export const renderRubricLink = (orgData) => {
  return (
    orgData?.rubric_link && (
      <Link color={DOPPEL_CLOUD_BLUE_SHADE} href={orgData.rubric_link} isExternal>
        Customer Rubric
      </Link>
    )
  )
}

export const renderFlaggedUrl = (flaggedUrl) => {
  return (
    <FormControl>
      <VStack align="left">
        <DoppelFormLabel
          formLabel="Entity"
          helperText="The entity that will be reported to the platform"
        />

        <TextInputField dbValue={flaggedUrl} />
      </VStack>
    </FormControl>
  )
}

export const getEmailTemplateWithAdditionalNotes = (
  emailBody: string,
  template: string,
) => {
  const indexOfLastNewline = template.lastIndexOf('\\n')
  return (
    template.slice(0, indexOfLastNewline - 2) +
    ' \\n\\n' +
    emailBody +
    ' ' +
    template.slice(indexOfLastNewline - 2)
  )
}

export const renderUploadScreenshot = (selectedFile, setSelectedFile) => {
  const screenshotDimensions = {
    maxImgHeight: '75px',
    maxImgWidth: '210px',
    maxDropboxHeight: '75px',
    maxDropboxWidth: '210px',
  }

  return (
    <FormControl alignContent={'center'} isRequired>
      <FormLabel fontSize={13}>Screenshot:</FormLabel>

      {renderScreenshotDropbox(selectedFile, setSelectedFile, screenshotDimensions)}
    </FormControl>
  )
}

type MaxDimensions = {
  maxImgHeight: any
  maxImgWidth: any
  maxDropboxHeight: any
  maxDropboxWidth: any
}
export const renderScreenshotDropbox = (
  selectedFile,
  setSelectedFile,
  maxDimensions: Partial<MaxDimensions> = {
    maxImgHeight: null,
    maxImgWidth: null,
    maxDropboxHeight: null,
    maxDropboxWidth: null,
  },
) => {
  // Suggest only set maxImgHeight and maxImgWidth only to prevent the image from getting too big
  // Don't suggest changing maxDropboxHeight and maxDropboxWidth, makes FE look weird sometimes
  return selectedFile ? (
    <>
      <Image
        {...(maxDimensions.maxImgHeight && { maxHeight: maxDimensions.maxImgHeight })}
        {...(maxDimensions.maxImgWidth && { maxWidth: maxDimensions.maxImgWidth })}
        src={URL.createObjectURL(selectedFile)}
      />

      <Spacer />
    </>
  ) : (
    <>
      <Box
        {...(maxDimensions.maxDropboxHeight && {
          maxHeight: maxDimensions.maxDropboxHeight,
        })}
        {...(maxDimensions.maxDropboxWidth && {
          maxWidth: maxDimensions.maxDropboxWidth,
        })}
      >
        <FileDropzone
          acceptedFileTypes={{
            'image/*': ['.jpeg', '.png'],
          }}
          setSelectedFile={setSelectedFile}
        />
      </Box>
    </>
  )
}

export const renderReasonsAutoStagingDisabled = (reasonsAutoStagingDisabled) => {
  return (
    <Stack>
      <Text color={DOPPEL_BREACH_RED} fontSize={'small'}>
        Reason(s) why platform(s) were not auto-staged:
      </Text>

      <List>
        {reasonsAutoStagingDisabled.map((disabledEnforcement) => {
          // provide additional info on why auto-staging was disabled with parameters if they exist
          const additionalInfo =
            disabledEnforcement.params &&
            Object.keys(disabledEnforcement.params).length > 0
              ? `Additional Info: ${Object.entries(disabledEnforcement.params)
                  .map(([key, value]) => `${key} is ${value || 'empty'}`)
                  .join(', ')}`
              : ''
          return (
            <ListItem
              fontSize={'small'}
              key={disabledEnforcement.platform + disabledEnforcement.reason}
            >
              <Text
                color={DOPPEL_BREACH_RED}
              >{`Enforcement not auto-staged for ${disabledEnforcement.platform} platform(s) because ${disabledEnforcement.reason}. ${additionalInfo}`}</Text>
            </ListItem>
          )
        })}
      </List>
    </Stack>
  )
}

export const renderReasonsStagingDisabled = (reasonsStagingDisabled) => {
  return (
    <Stack>
      <Text color={DOPPEL_BREACH_RED} fontSize={'small'}>
        Please visit the{' '}
        <Link href={PageRoute.ORGANIZATIONS} isExternal textDecoration="underline">
          Organizations Page
        </Link>{' '}
        to input Org-level requirements (Legal Name, Auth Letter, etc.).
        <Spacer />
        Please visit the{' '}
        <Link href={PageRoute.TRADEMARKS} isExternal textDecoration="underline">
          Trademarks Page
        </Link>{' '}
        to create or update a relevant Trademark.
      </Text>

      <Text color={DOPPEL_BREACH_RED} fontSize={'small'}>
        Config fields usually need to be filled out on an Org, Brand, Trademark, or
        other config level (see above for links).
        <Spacer />
        Missing trademark fields could indicate no trademark has been selected as well.
        <Spacer />
        User input fields need to be filled out within this form itself.
      </Text>

      <Text color={DOPPEL_BREACH_RED} fontSize={'small'} fontWeight="bold">
        Reason(s) staging is disabled:
      </Text>

      <List>
        {reasonsStagingDisabled.map((reason) => (
          <ListItem fontSize={'small'} key={reason}>
            <Text color={DOPPEL_BREACH_RED}>{reason}</Text>
          </ListItem>
        ))}
      </List>
    </Stack>
  )
}

export const stageEnforcementButtonDisabledReasonsTooltip = (
  buttonComponent,
  isEnforcementValid,
  reasonsStagingDisabled,
  action = 'stage',
) => {
  return (
    <Tooltip
      label={
        isEnforcementValid
          ? `Will ${action} enforcement`
          : `Not valid: ${reasonsStagingDisabled.join(', ')}`
      }
      placement="top-start"
    >
      <span>{buttonComponent}</span>
    </Tooltip>
  )
}

export const getUniqueEnforcementActions = (enforcementActions) => {
  if (!Array.isArray(enforcementActions) || enforcementActions.length === 0) {
    return []
  }
  // Filter unique enforcement methods by all specified fields
  const actions = enforcementActions
    .reduce((acc, currAction) => {
      const existingAction = acc.find(
        (action) =>
          action.enforcement_method_type === currAction.enforcement_method_type &&
          action.enforcement_method_data === currAction.enforcement_method_data &&
          action.priority === currAction.priority &&
          action.stage === currAction.stage,
      )
      if (!existingAction) {
        acc.push(currAction)
      }
      return acc
    }, [])
    .map((action) => ({
      id: action.id,
      enforcement_method_data: action.enforcement_method_data,
      enforcement_method_type: action.enforcement_method_type,
      priority: action.priority,
      stage: action.stage,
    }))

  return actions
}

export const removePrefixFromArchetype = (archetype: string) => {
  const prefix = SOCIAL_MEDIA_ARCHETYPE_PREFIX
  if (archetype.startsWith(prefix)) {
    return archetype.slice(prefix.length)
  }
  return archetype
}

export const getSortedAlertAssigneesWithUnassigned = (
  alertAssignees,
  currentUserId = null,
) => {
  const sortedAssignees = [...alertAssignees].sort((a, b) =>
    a.email.localeCompare(b.email),
  )

  if (currentUserId) {
    sortedAssignees.sort((a, b) => {
      if (a.id === currentUserId) return -1 // Move `a` to the start
      if (b.id === currentUserId) return 1 // Move `b` to the start
      return 0 // Leave other elements in their original order
    })
  }

  sortedAssignees.unshift({
    id: ZERO_UUID,
    email: 'Unassigned',
    name: 'Unassigned',
    is_internal: true,
    created_at: null,
  })
  return sortedAssignees
}

export const getColorForEnforcementStatus = (
  enforcementStatus: ENFORCEMENT_STATUS,
): string => {
  let bgColor = DOPPEL_ENCRYPT_GREEN_SHADE
  switch (enforcementStatus) {
    case ENFORCEMENT_STATUS.REJECTED:
    case ENFORCEMENT_STATUS.FAILED:
    case ENFORCEMENT_STATUS.CANCELED:
    case ENFORCEMENT_STATUS.RETRACTED:
      bgColor = DOPPEL_BREACH_RED
      break
    case ENFORCEMENT_STATUS.BLOCKED:
      bgColor = DOPPEL_FIREWALL_ORANGE
      break
  }
  return bgColor
}

export const getProgressStateFromEnforcementStatus = (
  status,
  enforcementRequest,
  isEmployeeView,
) => {
  const format = ALERT_DETAILED_VIEW_TIMESTAMP_FORMAT
  if (status === ENFORCEMENT_STATUS.REPORTED && enforcementRequest.submitted_at)
    return {
      label: ENFORCEMENT_STATUS.REPORTED,
      info: `
      Submitted ${getTimestampDisplay(enforcementRequest.submitted_at, format)} by ${
        getExternalUser(enforcementRequest?.user, isEmployeeView) || 'unknown'
      }
    `,
    }

  if (
    status === ENFORCEMENT_STATUS.APPROVED &&
    enforcementRequest.status === ENFORCEMENT_STATUS.APPROVED
  ) {
    return {
      label: ENFORCEMENT_STATUS.APPROVED,
      info:
        enforcementRequest.updated_at &&
        `
      Approved on ${getTimestampDisplay(enforcementRequest.updated_at, format)}
    `,
    }
  }

  if (
    status === ENFORCEMENT_STATUS.REROUTED &&
    enforcementRequest.status === ENFORCEMENT_STATUS.REROUTED
  ) {
    return {
      label: ENFORCEMENT_STATUS.REROUTED,
      info:
        enforcementRequest.updated_at &&
        `
      Rerouted to provider on ${getTimestampDisplay(
        enforcementRequest.updated_at,
        format,
      )}
    `,
    }
  }

  if (status === ENFORCEMENT_STATUS.STAGED) {
    return {
      label: ENFORCEMENT_STATUS.STAGED,
      info: `
      Request created on ${getTimestampDisplay(enforcementRequest.created_at, format)}
      `,
    }
  }

  if (
    status === ENFORCEMENT_STATUS.BLOCKED &&
    enforcementRequest.status === ENFORCEMENT_STATUS.BLOCKED
  )
    return {
      label: ENFORCEMENT_STATUS.BLOCKED,
      info: `
      Blocked on ${getTimestampDisplay(enforcementRequest.updated_at, format)} by ${
        getExternalUser(enforcementRequest?.user, isEmployeeView) || 'unknown'
      }
    `,
    }

  if (
    status === ENFORCEMENT_STATUS.RETRACTION_SENT &&
    enforcementRequest.status === ENFORCEMENT_STATUS.RETRACTION_SENT
  ) {
    return {
      label: ENFORCEMENT_STATUS.RETRACTION_SENT,
      info:
        enforcementRequest.updated_at &&
        `
      Retraction sent on ${getTimestampDisplay(enforcementRequest.updated_at, format)}
    `,
    }
  }

  if (
    status === ENFORCEMENT_STATUS.RETRACTED &&
    enforcementRequest.status === ENFORCEMENT_STATUS.RETRACTED
  ) {
    return {
      label: ENFORCEMENT_STATUS.RETRACTED,
      info:
        enforcementRequest.updated_at &&
        `
      Retracted on ${getTimestampDisplay(enforcementRequest.updated_at, format)}
    `,
    }
  }

  if (status === ENFORCEMENT_STATUS.REJECTED) {
    return {
      label: ENFORCEMENT_STATUS.REJECTED,
      info: `
      Rejected on ${getTimestampDisplay(enforcementRequest.created_at, format)}
      `,
    }
  }

  return {
    label: status,
  }
}

export const ENFORCEMENT_TYPE_TAGS_BY_PRODUCT = {
  domains: [
    EnforcementTypeReportTag.PHISHING,
    EnforcementTypeReportTag.TRADEMARK,
    EnforcementTypeReportTag.COPYRIGHT,
  ],
  default: [],
}

export const getSubmissionProofObjectName = (submission_proof_url) => {
  const lastSlashIndex: number = submission_proof_url.lastIndexOf('/')
  return submission_proof_url.substring(lastSlashIndex + 1)
}
