import { getColorForEnforcementStatus } from '@/utils/enforcement_utils'
import EditDropdown from '../shared/edit_dropdown'
import { useUpdateEnforcementRequestStatusMutation } from '@/generated/graphql'
import { useApolloClient } from '@apollo/client'
import { ENFORCEMENT_STATUS } from '@/utils/constants'
import { delayRefetchedQueries } from '@/utils'

export const EnforcementStatusDropdown = ({ enforcementRequest }) => {
  const client = useApolloClient()
  const [updateEnforcementRequestStatus, { loading: updateEnforcementStatusLoading }] =
    useUpdateEnforcementRequestStatusMutation()

  return (
    <EditDropdown
      currentValue={enforcementRequest.status}
      getColorForValues={getColorForEnforcementStatus}
      isForEmployee
      onChange={(newStatus) => {
        updateEnforcementRequestStatus({
          variables: {
            update_enforcement_request_status_input: {
              enforcement_request_id: enforcementRequest.id,
              enforcement_request_status: newStatus as ENFORCEMENT_STATUS,
              source: 'ui',
            },
          },
        })
        delayRefetchedQueries(client, ['GetEnforcementRequestsForSpoofingReport'])
      }}
      values={[
        ENFORCEMENT_STATUS.APPROVED,
        ENFORCEMENT_STATUS.REROUTED,
        ENFORCEMENT_STATUS.REPORTED,
        ENFORCEMENT_STATUS.REJECTED,
        ENFORCEMENT_STATUS.BLOCKED,
        ...(enforcementRequest.status === ENFORCEMENT_STATUS.STAGED
          ? [ENFORCEMENT_STATUS.CANCELED]
          : []),
        ...(enforcementRequest.status === ENFORCEMENT_STATUS.RETRACTION_SENT
          ? [ENFORCEMENT_STATUS.RETRACTED]
          : []),
      ]}
    />
  )
}
