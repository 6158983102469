import {
  Flex,
  HStack,
  TabList,
  Tabs,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import OrgPicker from '../../components/internal/org_picker'
import { DOPPEL_DARK, DOPPEL_WHITE } from '../../utils/style'
import InternalDropdown from '@/components/internal/internal_dropdown'
import FeatureFlagDisplay from '@/components/internal/feature_flag_display'
import { MainTab } from '@/components/reports/main_tab'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useAtomValue } from 'jotai'
import { userProductsAtom } from '@/atoms/permissions'
import { isUserAuthorizedForPage } from '@/utils/permissions'
import NotFoundPage from '@/components/not_found_page'

export type TabInfo = {
  name: string
  route: string
  isVisible: boolean
  subTabs?: TabInfo[]
  count?: number
}

export type SubTabInfo = {
  name: string
  route: string
  isVisible: boolean
}

type PageLayoutProps = {
  component: JSX.Element
  tabs?: TabInfo[]
  onTabChange?: (tab: TabInfo, index: number) => void
  onSubTabChange?: (subTab: SubTabInfo, index: number) => void
}

const getTabIndexForRoute = (tabs, route) => {
  const initTab = tabs.findIndex((tab) => route.startsWith(tab.route))
  return initTab > 0 ? initTab : 0
}

const PageLayout = (props: PageLayoutProps) => {
  const {
    component,
    tabs = [],
    onTabChange = () => {},
    onSubTabChange = () => {},
  } = props

  const router = useRouter()
  const [tabIndex, setTabIndex] = useState(getTabIndexForRoute(tabs, router.asPath))
  const subTabs = tabs[tabIndex]?.subTabs
  const [subTabIndex, setSubTabIndex] = useState(0)
  const userProducts = useAtomValue(userProductsAtom)
  const isRbacEnabled = useFeatureFlagEnabled('rbac')

  useEffect(() => {
    setTabIndex(getTabIndexForRoute(tabs, router.asPath))
    if (!subTabs) return
    setSubTabIndex(getTabIndexForRoute(subTabs, router.asPath))
  }, [tabs, subTabs, router.asPath])

  const bgColor = useColorModeValue(DOPPEL_WHITE, DOPPEL_DARK)

  if (!isUserAuthorizedForPage(router.pathname, userProducts, isRbacEnabled)) {
    return <NotFoundPage />
  }

  return (
    <VStack bgColor={bgColor} width="100%">
      <VStack marginBottom={2} width="100%">
        <Flex width="100%">
          <Tabs
            index={tabIndex}
            isLazy={true}
            marginLeft={1}
            onChange={(index) => {
              setTabIndex(index)
              onTabChange(tabs[index], index)
            }}
            variant="solid-rounded"
            w="100%"
          >
            <TabList borderRadius="4px">
              {tabs.map((tab) => (
                <MainTab count={tab.count} key={tab.name} name={tab.name} />
              ))}
            </TabList>
          </Tabs>

          <HStack>
            <OrgPicker />

            <InternalDropdown />

            <FeatureFlagDisplay />
          </HStack>
        </Flex>

        {subTabs?.length && (
          <Flex justifyContent="center" width="100%">
            <Tabs
              index={subTabIndex}
              isLazy={true}
              marginLeft={1}
              onChange={(index) => {
                setSubTabIndex(index)
                onSubTabChange(subTabs[index], index)
              }}
              variant="solid-rounded"
              w="100%"
            >
              <TabList>
                {subTabs.map((tab) => (
                  <MainTab key={tab.name} name={tab.name} />
                ))}
              </TabList>
            </Tabs>
          </Flex>
        )}
      </VStack>

      {component}
    </VStack>
  )
}

export default PageLayout
