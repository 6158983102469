import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Th, Thead, Tooltip, Tr } from '@chakra-ui/react'

import { IoMdHelpCircle } from 'react-icons/io'

const TableHeader = ({ headerGroups, headerStyle, postHeaderContent }) => {
  return (
    <Thead>
      {headerGroups.map((headerGroup, i) => (
        <Tr {...headerGroup.getHeaderGroupProps()} key={i}>
          {headerGroup.headers.map((column) => (
            <Th
              {...column.getHeaderProps()}
              fontSize={12}
              key={column.id}
              style={headerStyle}
            >
              <div {...column.getSortByToggleProps()}>{column.render('Header')}</div>

              <div>
                {column.header_tooltip ? (
                  <Tooltip fontSize="sm" label={column.header_tooltip}>
                    <span>
                      <IoMdHelpCircle />
                    </span>
                  </Tooltip>
                ) : null}
              </div>

              <div>{column.canFilter ? column.render('Filter') : null}</div>

              <div>
                {column.isSorted ? (
                  <>{column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon />}</>
                ) : (
                  // NOTE (arifin): hidden element to avoid jitter when toggling
                  <div style={{ visibility: 'hidden' }}>
                    <ChevronDownIcon />
                  </div>
                )}
              </div>
            </Th>
          ))}

          {i === 0 && postHeaderContent && <Th>{postHeaderContent}</Th>}
        </Tr>
      ))}
    </Thead>
  )
}

export default TableHeader
