import { DesignatedTakedownAssetStatus, ProductType } from '@/generated/enums'
import { useGetSpoofTemplateInputLazyQuery } from '@/generated/graphql'
import { REPORTING_AGENT } from '@/utils/constants'
import { useEffect, useState } from 'react'
import { Spoofing_Reports } from '@/generated/graphql'

export function useFetchTemplateInput(
  selectedSpoofReport,
  selectedEnforcementPlatform,
) {
  const [getSpoofTemplateInput, { data: templateInput, loading, error }] =
    useGetSpoofTemplateInputLazyQuery()

  useEffect(() => {
    if (!selectedSpoofReport || !selectedEnforcementPlatform) return

    const designatedTakedownAssetsWhere = {
      _and: [
        {
          status: { _eq: DesignatedTakedownAssetStatus.ACTIVE },
        },
        selectedSpoofReport?.platform_subtype?.product === ProductType.DOMAIN
          ? {
              customer_asset: {
                platform_subtype: { product: { _eq: ProductType.DOMAIN } },
              },
            }
          : {
              customer_asset: {
                platform_subtype: { platform: { _eq: selectedEnforcementPlatform } },
              },
            },
      ],
    }

    // Execute the lazy query
    getSpoofTemplateInput({
      variables: {
        entityId: selectedSpoofReport.original_entity_id,
        designatedTakedownAssetsWhere,
      },
    })
  }, [selectedSpoofReport, selectedEnforcementPlatform])

  return { templateInput, loading, error }
}

type TemplateInfoInputsProps = {
  templateInput: any
  setTemplateInfoInputs: (inputs: Record<string, any>) => void
  selectedEnforcementPlatform: any
  submissionEmail: string
  selectedSpoofReport: any
  shouldDefangUrls: boolean
  designatedTakedownAssets?: any | null
  linkedAlertData?: Spoofing_Reports[] | null
}

function defangUrl(url?: string, shouldDefang = true): string {
  /**
   * Converts a standard URL into a defanged URL.
   * For example, converts 'http://example.com' to 'hxxp[://]example[.]com'.
   */
  if (url && shouldDefang) {
    const defangedUrl = url
      .replace(/\./g, '[.]')
      .replace('://', '[://]')
      .replace('http', 'hxxp')
      .replace('@', '[at]')
    return defangedUrl
  }
  return url
}

function useTemplateInfoInputs({
  templateInput,
  setTemplateInfoInputs,
  selectedEnforcementPlatform,
  submissionEmail,
  selectedSpoofReport,
  shouldDefangUrls,
  linkedAlertData = null,
}: TemplateInfoInputsProps): void {
  const [flaggedUrl, setFlaggedUrl] = useState(null)
  const [matchedUrls, setMatchedUrls] = useState([])

  useEffect(() => {
    setTemplateInfoInputs((prevState) => ({
      ...prevState,
      flagged_url: defangUrl(flaggedUrl, shouldDefangUrls),
      matched_urls: matchedUrls
        .map((url) => defangUrl(url, shouldDefangUrls))
        .join(', '),
    }))
  }, [shouldDefangUrls])

  useEffect(() => {
    const setTemplateInputs = async () => {
      let enforcementMetadata =
        templateInput?.spoofing_entities[0]?.organization?.enforcement_metadata || {}

      // Removing __typename from enforcementMetadata
      if (enforcementMetadata && '__typename' in enforcementMetadata) {
        const { ['__typename']: _, ...sanitizedMetadata } = enforcementMetadata
        enforcementMetadata = sanitizedMetadata
      }

      setFlaggedUrl(selectedSpoofReport?.flagged_url)
      setMatchedUrls([
        ...(selectedSpoofReport?.spoof_matches?.map(
          (match) => match?.full_url?.url,
          shouldDefangUrls,
        ) || []),
        ...(linkedAlertData
          ?.map((report) => report?.flagged_url ?? null)
          .filter((url) => url !== null) || []),
      ])

      // Set moreInfoInputs
      setTemplateInfoInputs((prevState) => ({
        ...prevState,
        slug:
          selectedSpoofReport?.spoof_matches?.[0]?.social_media_data?.slug ||
          selectedSpoofReport?.social_media_data?.slug ||
          null,
        ...enforcementMetadata,
        option: '',
        platform: selectedEnforcementPlatform,
        flagged_url: defangUrl(flaggedUrl, shouldDefangUrls),
        matched_urls: matchedUrls
          .map((url) => defangUrl(url, shouldDefangUrls))
          .join(', '),
        reporting_agent: REPORTING_AGENT,
        // TODO: fix this make it use the 7 day screenshot urls
        screenshot_urls:
          selectedSpoofReport?.screenshot_url ||
          selectedSpoofReport?.spoof_matches
            .map((match) => match?.full_url?.screenshot_url)
            .join(', '),
        ip_address: selectedSpoofReport?.domain_data?.ip_address
          ? 'Domain IP Address: ' + selectedSpoofReport?.domain_data?.ip_address
          : '',
        entity_url:
          templateInput?.designated_takedown_assets?.[0]?.customer_asset.value,
        submission_email: submissionEmail,
        protected_assets: (templateInput?.designated_takedown_assets ?? []).map(
          (asset) => asset.customer_asset.value,
        ),
      }))
    }

    setTemplateInputs()
  }, [
    selectedSpoofReport,
    templateInput,
    submissionEmail,
    selectedEnforcementPlatform,
    templateInput?.designatedTakedownAssets,
  ])
}

export default useTemplateInfoInputs
