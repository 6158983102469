import {
  DOPPEL_BUTTON_GREY,
  FONT_SIZE_MEDIUM,
  DOPPEL_TEXT_WHITE,
  DOPPEL_BUTTON_GREY_SHADE,
} from '@/utils/style'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, Flex, MenuButton, Spacer } from '@chakra-ui/react'
import { forwardRef, ReactNode } from 'react'

type DropdownButtonProps = {
  /** Additional icon shown to the left of the text */
  icon?: ReactNode
  /** Text shown on the button */
  label: ReactNode
  /** True if this is rendered in the context of a Chakra Menu */
  useMenuButton?: boolean
  /** Additional props to pass to the button */
  [key: string]: any
}

/**
 * This is a purely visual component, with no logic or functionality.
 * It serves as the default button which activates the Dropdown,
 * but can be used in custom dropdown contexts as well (such as the DateFilterDropdown).
 */
const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ icon, label, useMenuButton = false, ...props }: DropdownButtonProps, ref) => {
    const ButtonComponent = useMenuButton ? MenuButton : Button
    return (
      <ButtonComponent
        {...(useMenuButton ? { as: Button } : {})}
        _active={{ bg: DOPPEL_BUTTON_GREY_SHADE }}
        _hover={{ bg: DOPPEL_BUTTON_GREY_SHADE }}
        bg={DOPPEL_BUTTON_GREY}
        display="flex"
        fontSize={FONT_SIZE_MEDIUM}
        height={9}
        pl={3}
        pr={2}
        ref={ref}
        rightIcon={<ChevronDownIcon h={5} w={5} />}
        textColor={DOPPEL_TEXT_WHITE}
        {...props}
      >
        <Flex alignItems="center">
          {icon && (
            <>
              {icon}

              <Spacer mx={1} />
            </>
          )}

          {label}
        </Flex>
      </ButtonComponent>
    )
  },
)

DropdownButton.displayName = 'DropdownButton'

export default DropdownButton
