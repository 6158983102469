import {
  ReportSource,
  ReportSourceCategory,
  ReportSourceLabels,
  PageRoute,
  PlatformSubtype,
  ReportSourceCategoryLabels,
  ReportStatus,
  ProductType,
  ProductTypePath,
  Archetype,
} from '@/generated/enums'
import { Spoof_Matches } from '../../generated/graphql'
import { Classification, ReportType } from '../constants'
import { validate as uuidValidate } from 'uuid'
import { NextRouter } from 'next/router'
import { computeQueryParams } from './query_params'
import { getBucketNameAndObjectName } from '@/utils/gcs_utils'

export const REPORT_TYPE_TO_PATH: { [key in ReportType]: string } = {
  [ReportType.DOMAINS]: ProductTypePath.DOMAIN,
  [ReportType.SOCIAL_MEDIA]: ProductTypePath.SOCIAL_MEDIA,
  [ReportType.ECOMMERCE]: ProductTypePath.ECOMMERCE,
  [ReportType.EMAIL]: ProductTypePath.EMAIL,
  [ReportType.MOBILE_APPS]: ProductTypePath.MOBILE_APP,
  [ReportType.PAID_ADS]: ProductTypePath.PAID_AD,
  [ReportType.CRYPTO]: ProductTypePath.CRYPTO,
  [ReportType.NFTS]: ProductTypePath.NFT,
  [ReportType.METAVERSE]: ProductTypePath.METAVERSE,
  [ReportType.DARK_WEB]: ProductTypePath.DARKWEB,
  [ReportType.DARK_MARKET]: ProductTypePath.DARK_MARKET,
  [ReportType.CODE_REPOS]: ProductTypePath.CODE_REPOS,
  [ReportType.TELCO]: ProductTypePath.TELCO,
  [ReportType.SUSPICIOUS_EMAILS]: ProductTypePath.SUSPICIOUS_EMAILS,
}

export const productTypeToPath = (productType): string => {
  return productType ? productType.replace('_', '') : ''
}

export const PATH_TO_REPORT_TYPE: { [key: string]: ReportType } = Object.entries(
  REPORT_TYPE_TO_PATH,
).reduce((acc, [key, value]) => {
  acc[value] = key
  return acc
}, {} as { string: ReportType })

export const getReportTypeFromPath = (router: NextRouter): ReportType => {
  let reportTypePathSegment: string = null
  if (router.query.alert_type) {
    // handles the unified reports page: /admin/alerts/[alert_type]
    reportTypePathSegment = router.query.alert_type as string
  }
  const firstPathSegment = router.pathname.split('/')[1]
  if (firstPathSegment in PATH_TO_REPORT_TYPE) {
    // handles the main reports page: /[alert_type] (which doesn't use query param)
    reportTypePathSegment = firstPathSegment
  }
  return PATH_TO_REPORT_TYPE[reportTypePathSegment] ?? null
}

export const isTableViewPath = (router: NextRouter): boolean => {
  if (router.query.report_type) return true // handles all alerts page
  const firstPathSegment = router.pathname.split('/')[1]
  const secondPathSegment = router.pathname.split('/')[2]
  const validPaths = [...Object.keys(PATH_TO_REPORT_TYPE), 'reports', 'alerts']
  return validPaths.includes(firstPathSegment) && !secondPathSegment
}

export const PLATFORM_TYPE_DISPLAY_MAP: {
  [key in ReportType]?: ProductType
} = {
  [ReportType.DARK_MARKET]: ProductType.DARK_MARKET,
  [ReportType.DARK_WEB]: ProductType.DARKWEB,
  [ReportType.METAVERSE]: ProductType.METAVERSE,
  [ReportType.DOMAINS]: ProductType.DOMAIN,
  [ReportType.SOCIAL_MEDIA]: ProductType.SOCIAL_MEDIA,
  [ReportType.ECOMMERCE]: ProductType.ECOMMERCE,
  [ReportType.EMAIL]: ProductType.EMAIL,
  [ReportType.MOBILE_APPS]: ProductType.MOBILE_APP,
  [ReportType.PAID_ADS]: ProductType.PAID_AD,
  [ReportType.CRYPTO]: ProductType.CRYPTO,
  [ReportType.NFTS]: ProductType.NFT,
  [ReportType.CODE_REPOS]: ProductType.CODE_REPOS,
  [ReportType.TELCO]: ProductType.TELCO,
  [ReportType.SUSPICIOUS_EMAILS]: ProductType.SUSPICIOUS_EMAILS,
}

export const PRODUCT_TYPE_TO_REPORT_TYPE_MAP: {
  [key in ProductType]?: ReportType
} = {
  [ProductType.DARK_MARKET]: ReportType.DARK_MARKET,
  [ProductType.DARKWEB]: ReportType.DARK_WEB,
  [ProductType.METAVERSE]: ReportType.METAVERSE,
  [ProductType.DOMAIN]: ReportType.DOMAINS,
  [ProductType.SOCIAL_MEDIA]: ReportType.SOCIAL_MEDIA,
  [ProductType.ECOMMERCE]: ReportType.ECOMMERCE,
  [ProductType.EMAIL]: ReportType.EMAIL,
  [ProductType.MOBILE_APP]: ReportType.MOBILE_APPS,
  [ProductType.PAID_AD]: ReportType.PAID_ADS,
  [ProductType.CRYPTO]: ReportType.CRYPTO,
  [ProductType.NFT]: ReportType.NFTS,
  [ProductType.CODE_REPOS]: ReportType.CODE_REPOS,
  [ProductType.TELCO]: ReportType.TELCO,
  [ProductType.SUSPICIOUS_EMAILS]: ReportType.SUSPICIOUS_EMAILS,
}

export const PATH_TO_PRODUCT_TYPE: { [key: string]: ProductType } = {
  [ProductTypePath.DOMAIN]: ProductType.DOMAIN,
  [ProductTypePath.NFT]: ProductType.NFT,
  [ProductTypePath.SOCIAL_MEDIA]: ProductType.SOCIAL_MEDIA,
  [ProductTypePath.MOBILE_APP]: ProductType.MOBILE_APP,
  [ProductTypePath.DARKWEB]: ProductType.DARKWEB,
  [ProductTypePath.DARK_MARKET]: ProductType.DARK_MARKET,
  [ProductTypePath.ECOMMERCE]: ProductType.ECOMMERCE,
  [ProductTypePath.EMAIL]: ProductType.EMAIL,
  [ProductTypePath.PAID_AD]: ProductType.PAID_AD,
  [ProductTypePath.CRYPTO]: ProductType.CRYPTO,
  [ProductTypePath.TELCO]: ProductType.TELCO,
  [ProductTypePath.SUSPICIOUS_EMAILS]: ProductType.SUSPICIOUS_EMAILS,
  [ProductTypePath.PII]: ProductType.PII,
  [ProductTypePath.DOMAIN_PURCHASING]: ProductType.DOMAIN_PURCHASING,
  [ProductTypePath.CODE_REPOS]: ProductType.CODE_REPOS,
  [ProductTypePath.METAVERSE]: ProductType.METAVERSE,
}

export const REPORT_TYPES_WITH_PLATFORMS: ReportType[] = [
  ReportType.SOCIAL_MEDIA,
  ReportType.MOBILE_APPS,
  ReportType.DARK_WEB,
  ReportType.DARK_MARKET,
  ReportType.ECOMMERCE,
  ReportType.PAID_ADS,
  ReportType.CRYPTO,
]

export const METAVERSE_TYPES = ['roblox']
export const METAVERSE_PLATFORM_SUBTYPES = [PlatformSubtype.ROBLOX]

export const DARK_WEB_TYPES = ['darkweb', 'cred_leaks', 'credit_card_leaks']

export const CODE_REPOS_TYPES = ['github']
export const CODE_REPOS_PLATFORM_SUBTYPES = [PlatformSubtype.GITHUB]

export const getBestMatchFlaggedUrl = (spoofMatches: Spoof_Matches[]) => {
  if (!spoofMatches || spoofMatches.length === 0) {
    return null
  }

  const classifications = Object.values(Classification)
  const sortedSpoofMatches = [...spoofMatches].sort(
    (a, b) =>
      classifications.indexOf(a.classification) -
      classifications.indexOf(b.classification),
  )
  return sortedSpoofMatches[0]?.full_url?.url
}

export const getBestMatch = (spoofMatches: Spoof_Matches[]) => {
  if (!spoofMatches || spoofMatches.length === 0) {
    return null
  }

  const classifications = Object.values(Classification)
  const sortedSpoofMatches = [...spoofMatches].sort(
    (a, b) =>
      classifications.indexOf(a.classification) -
      classifications.indexOf(b.classification),
  )
  return sortedSpoofMatches[0]
}

export const getTypesFromReportType = (
  reportType: ReportType,
  reportPlatformTypes: string[],
): string[] => {
  // metaverse and dark web are special cases for now
  switch (reportType) {
    case ReportType.METAVERSE:
      return METAVERSE_TYPES
    case ReportType.DARK_WEB:
      return DARK_WEB_TYPES
    default:
      return reportPlatformTypes || []
  }
}

export const getDoppelFullExternalId = (externalId: number, orgAbbrName: string) => {
  return `${orgAbbrName}-${externalId}`
}

export const getDoppelLink = (
  reportId: string,
  product = 'nfts',
  orgAbbrName?: string,
  externalId?: number,
): string => {
  const path = (() => {
    switch (product) {
      case ProductType.SUSPICIOUS_EMAILS:
        return 'suspicious-emails'
      case 'nfts':
        return 'nfts'
      default:
        return 'alerts'
    }
  })()

  if (orgAbbrName && externalId) {
    return `https://app.doppel.com/${path}/${getDoppelFullExternalId(
      externalId,
      orgAbbrName,
    )}`
  }
  return `https://app.doppel.com/${path}/${reportId}`
}

export const EXTERNAL_QUEUE_STATES = [
  ReportStatus.NEEDS_REVIEW,
  ReportStatus.NEEDS_ACTION,
  ReportStatus.REPORTED,
  ReportStatus.RESOLVED,
  ReportStatus.NO_ACTION,
  ReportStatus.ARCHIVED,
]

export const getReportStatuses = (isEmployeeView: boolean): ReportStatus[] => {
  const reportStatuses = [...EXTERNAL_QUEUE_STATES]
  if (isEmployeeView) {
    reportStatuses.push(ReportStatus.INTERNAL_REVIEW, ReportStatus.INTERNAL_IGNORED)
  }

  return reportStatuses
}

export const getProductsByReportType = (selectedProduct: string): ProductType[] => {
  if (!selectedProduct) {
    return []
  }
  // get products for a given report type, if report type not in map then get all products
  const products: ProductType[] =
    selectedProduct in PLATFORM_TYPE_DISPLAY_MAP
      ? [PLATFORM_TYPE_DISPLAY_MAP[selectedProduct]]
      : Object.values(ProductType)

  // remove NFT from product types
  return products.filter((product) => product !== ProductType.NFT)
}

/**
 * Display a user, when internal users' identities should be hidden to customers
 *
 * @param user to be displayed to the client
 * @param isEmployeeView whether FE is in employee or customer view
 * @returns user's name, or 'Doppel' if user is internal and FE is in customer view
 */
export const getExternalUser = (
  user: any,
  isEmployeeView: boolean,
): string | undefined => {
  if (!user) return undefined
  if (!isEmployeeView && user?.is_internal) {
    return 'Doppel'
  }
  return user?.name?.split(' ')[0]
}

export const categoryToReportSources = (category: ReportSourceCategory) => {
  return Object.values(ReportSource).filter(
    (source) => (ReportSourceLabels[source] as ReportSourceCategory) === category,
  )
}

export const reportSourceToCategory = (source: ReportSource) => {
  return ReportSourceLabels[source] as ReportSourceCategory
}

export const getReportSourceDisplay = (report, isEmployeeView, orgName) => {
  const sourceCategory = reportSourceToCategory(report.source as ReportSource)
  const showCustomerSource = report.is_customer_sourced && report.uploader?.is_internal
  let displaySource = ReportSourceCategoryLabels[sourceCategory]
  if (sourceCategory === ReportSourceCategory.ANALYST_UPLOAD && report.uploader) {
    displaySource += `: ${getExternalUser(report.uploader, isEmployeeView)}`
  }
  if (showCustomerSource) {
    displaySource += ` on behalf of ${orgName}`
  }
  if (isEmployeeView) {
    displaySource += ` (${report.source})`
  }

  return displaySource
}

export function getLatestSnapshot(alert) {
  return alert?.spoof_matches?.length ? alert.spoof_matches[0] : null
}

// verifies that screenshot url and id match
export function verifyVersionedName(
  bucketName: string,
  screenshotUrl: string | null,
  id: string | null,
): boolean {
  if (!screenshotUrl || !id) return false
  const regex = new RegExp(`${bucketName}/(.+)`)
  const match = screenshotUrl?.match(regex)
  const matchInfo = match ? match[1] : null

  return matchInfo === id
}

export enum ScreenshotUrlTable {
  FULL_URLS = 'full_urls',
  SOCIAL_MEDIA_DATA = 'social_media_data',
  SOCIAL_MEDIA_POST = 'social_media_post',
  SOCIAL_MEDIA_GROUP = 'social_media_group',
  MOBILE_APPS = 'mobile_apps',
  SPOOFING_REPORTS = 'spoofing_reports',
}

export type ScreenshotInfo = {
  name: string
  table: ScreenshotUrlTable
  existing: boolean // true if versioned screenshot already exists, indicated by screenshot_url
  bucketName: string
  // ideally id === name and matches url, but this is not always the case;
  // including to increase flexibility on return conditions
  id?: string
  url?: string
}

function getScreenshotInfoWithSuccessCondition(
  alert,
  bucketName: string,
  condition: (screenshotInfo: ScreenshotInfo) => boolean,
): ScreenshotInfo | null {
  if (!alert) return null

  const computeInfo = (
    screenshotUrl: string | null,
    screenshotNameFromId: string | null,
    table: ScreenshotUrlTable,
  ): ScreenshotInfo | null => {
    if (!screenshotUrl && !screenshotNameFromId) return null
    const urlMatchesId = verifyVersionedName(
      bucketName,
      screenshotUrl,
      screenshotNameFromId,
    )
    // looks like a hack for social media screenshots being in various buckets
    const { bucketName: screenshotBucketName, objectName } = screenshotUrl
      ? getBucketNameAndObjectName(screenshotUrl)
      : { bucketName: undefined, objectName: undefined }
    return {
      name: objectName || screenshotNameFromId,
      table: table,
      existing: urlMatchesId,
      bucketName: screenshotBucketName || bucketName,
      id: screenshotNameFromId,
      url: screenshotUrl,
    }
  }

  // priority goes archetype table -> full urls -> report level screenshot
  const latestSnapshot = getLatestSnapshot(alert)

  if (latestSnapshot) {
    const archetype = alert.platform_subtype?.archetype
    if (archetype) {
      let screenshotInfo: ScreenshotInfo | null
      switch (archetype) {
        case Archetype.SOCIAL_MEDIA:
          screenshotInfo = computeInfo(
            latestSnapshot.social_media_data?.screenshot_url,
            latestSnapshot.social_media_data?.id,
            ScreenshotUrlTable.SOCIAL_MEDIA_DATA,
          )
          break
        case Archetype.SOCIAL_MEDIA_POST:
          screenshotInfo = computeInfo(
            latestSnapshot.social_media_post?.screenshot_url,
            latestSnapshot.social_media_post?.id,
            ScreenshotUrlTable.SOCIAL_MEDIA_POST,
          )
          break
        case Archetype.SOCIAL_MEDIA_GROUP:
          screenshotInfo = computeInfo(
            latestSnapshot.social_media_group?.screenshot_url,
            latestSnapshot.social_media_group?.id,
            ScreenshotUrlTable.SOCIAL_MEDIA_GROUP,
          )
          break
        case Archetype.MOBILE_APP:
          screenshotInfo = computeInfo(
            latestSnapshot.mobile_app?.screenshot_url,
            latestSnapshot.mobile_app?.id,
            ScreenshotUrlTable.MOBILE_APPS,
          )
          break
      }

      if (screenshotInfo && condition(screenshotInfo)) {
        return screenshotInfo
      }
    }
    // catches domains, and other archetypes could fall in here
    const fullUrlScreenshotInfo = computeInfo(
      latestSnapshot.full_url?.screenshot_url,
      latestSnapshot.full_url?.id,
      ScreenshotUrlTable.FULL_URLS,
    )
    if (fullUrlScreenshotInfo && condition(fullUrlScreenshotInfo)) {
      return fullUrlScreenshotInfo
    }
  }

  // if no versioned name found, check report level screenshot
  const alertLevelScreenshotInfo = computeInfo(
    alert.screenshot_url,
    alert.id,
    ScreenshotUrlTable.SPOOFING_REPORTS,
  )
  if (alertLevelScreenshotInfo && condition(alertLevelScreenshotInfo)) {
    return alertLevelScreenshotInfo
  }

  return null
}

export function getFallbackScreenshotInfo(
  alert,
  bucketName: string,
): ScreenshotInfo | null {
  return getScreenshotInfoWithSuccessCondition(
    alert,
    bucketName,
    (screenshotInfo) => !!screenshotInfo.url,
  )
}

export function getVersionedScreenshotInfo(
  alert,
  bucketName: string,
  useIdFallback = false, // for cases where image is stored correctly, but not reflected in screenshot_url
): ScreenshotInfo | null {
  const screenshotInfo = getScreenshotInfoWithSuccessCondition(
    alert,
    bucketName,
    (screenshotInfo) => screenshotInfo.existing,
  )
  if (screenshotInfo) return screenshotInfo
  if (useIdFallback) {
    return getScreenshotInfoWithSuccessCondition(
      alert,
      bucketName,
      (screenshotInfo) => !!screenshotInfo.id,
    )
  }
  return null
}

export const isInternalId = (report_id: string): boolean => {
  return uuidValidate(report_id)
}

export const getExternalReportId = (abbr_name: string, external_id: number): string => {
  return `${abbr_name}-${external_id}`
}

export const extractAlertIdOrgAbbrAndExternalId = (
  alert_id: string,
): {
  abbrName: string
  externalId: number
} => {
  const [abbrName, externalId] = alert_id.split('-')
  return { abbrName, externalId: Number(externalId) }
}

export const getDoppelReportUrl = (
  spoofReport,
  organization,
  isSuspiciousEmails = false,
): string => {
  // TODO DOP-4741 should only return external URL
  const orgAbbrName = organization?.abbr_name
  const externalId = spoofReport.external_id
  const reportId = spoofReport.id
  return getDoppelUrlFromId(
    externalId ? getDoppelFullExternalId(externalId, orgAbbrName) : reportId,
    isSuspiciousEmails,
  )
}

export const getDoppelUrlFromId = (
  alertId: string,
  isSuspiciousEmails = false,
): string => {
  return `${window.location.origin}${
    isSuspiciousEmails ? PageRoute.SUSPICIOUS_EMAILS : PageRoute.ALERTS
  }/${alertId}`
}

export const filterSpoofingReports = (data, selectedStatus) => {
  // legacy band-aid fix due to read replica lag
  // to prevent reports "staying" in the queue after being moved
  const reports = data?.search_spoofing_reports || data?.spoofing_reports

  if (!reports) {
    return []
  }

  const defaultStatus = ReportStatus.NEEDS_REVIEW
  const statusToFilter = selectedStatus == null ? defaultStatus : selectedStatus

  return reports.filter((report) => report.report_status === statusToFilter)
}

export const generateReportUrl = (
  selectedReportType,
  isOrgUnifiedView,
  selectedSpoofReport,
) => {
  if (!selectedReportType) return null
  let subdomain = isOrgUnifiedView ? PageRoute.UNIFIED_ALERTS : ''
  subdomain += `/${REPORT_TYPE_TO_PATH[selectedReportType]}`

  if (selectedSpoofReport) {
    const orgAbbrName = selectedSpoofReport.organization?.abbr_name
    const externalId = selectedSpoofReport.external_id
    const reportId = selectedSpoofReport.id

    const internalUrl = `${subdomain}/${reportId}`
    const externalUrl = `${subdomain}/${getDoppelFullExternalId(
      externalId,
      orgAbbrName,
    )}`

    // Fallback to internal URL if externalId is null
    return externalId ? externalUrl : internalUrl
  }

  return subdomain
}

export const generateTableViewUrl = (
  subdomain,
  selectedReportFilters,
  selectedReportStatus,
  selectedSortingMechanism,
) => {
  const paramString = computeQueryParams({
    reportFilters: selectedReportFilters,
    reportStatus: selectedReportStatus,
    sortingMechanism: selectedSortingMechanism,
  }).toString()

  return paramString.length ? `${subdomain}?${paramString}` : subdomain
}

export const shouldRenderQueueState = (queueState, isEmployee) => {
  if (isEmployee) {
    return true // Include all statuses if the user is an employee
  }
  // Exclude statuses that contain 'internal' if not an employee
  return !queueState.toLowerCase().includes('internal')
}
