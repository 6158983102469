import { MenuButton } from '@chakra-ui/react'
import DropdownMenu from '../shared/forms/dropdown_menu'
import { AlertTableColumnLabels } from '@/generated/enums'
import DoppelIconButton from '../shared/doppel_icon_button'
import { useEffect, useState } from 'react'
import { useAppSettings } from '@/utils/app_settings'
import { EditIcon } from '@chakra-ui/icons'

type SpoofReportsTableColumnEditDropdownProps = {
  availableColumnIds: string[]
  visibleColumnIds: string[]
}

const SpoofReportsTableColumnEditDropdown = ({
  availableColumnIds,
  visibleColumnIds,
}: SpoofReportsTableColumnEditDropdownProps) => {
  const {
    data: appSettings,
    loading: appSettingsLoading,
    update: updateAppSettings,
  } = useAppSettings()

  // local version of appSettings' column filter state, to prevent db round trip lag
  const [selectedColumnIds, setSelectedColumnIds] = useState<string[]>(visibleColumnIds)
  useEffect(() => setSelectedColumnIds(visibleColumnIds), [visibleColumnIds])

  const saveColumnOverrideSettings = () => {
    if (appSettingsLoading) return // prevent updating incorrect state

    // get diff from current available columns
    const changes = {}
    for (const colId of availableColumnIds) {
      // using col.show to get information of if it's visible by default, when no user override
      const currentlyVisible = visibleColumnIds.includes(colId)
      const nowVisible = selectedColumnIds.includes(colId)
      if (currentlyVisible && !nowVisible) {
        changes[colId] = false
      } else if (!currentlyVisible && nowVisible) {
        changes[colId] = true
      }
    }

    // send updates with these columns
    const newColumnOverride = { ...appSettings.alert_table_column_override, ...changes }
    updateAppSettings({
      alert_table_column_override: newColumnOverride,
    })
  }

  return (
    <DropdownMenu
      customButton={
        <MenuButton>
          <DoppelIconButton
            ariaLabel="Edit column visibility"
            icon={<EditIcon boxSize={3.5} />}
            ml={-2}
            mt={-4}
            size={7}
          />
        </MenuButton>
      }
      isMultiSelect
      itemDisplayFunction={(colId) => AlertTableColumnLabels[colId]}
      items={availableColumnIds}
      onClose={saveColumnOverrideSettings}
      placeholder="Find column..."
      selectedItems={selectedColumnIds}
      setSelectedItems={setSelectedColumnIds}
      showSearchBar
    />
  )
}

export default SpoofReportsTableColumnEditDropdown
