import { useGetGcsObjectQuery } from '@/generated/graphql'
import { useState } from 'react'
import {
  DOPPEL_VISION_ASSETS_BUCKETS,
  getBucketNameAndObjectName,
} from '@/utils/gcs_utils'
import { GCS_PREFIX } from '@/utils/web2'

/**
 * A hook that takes a GCS URL and optionally a version number and returns a
 * signed URL that can be used to access the GCS object.
 *
 * If the version is not provided, the latest version is used.
 *
 * If the URL is not a valid GCS URL, an object with null signedUrl, false
 * loading and null error is returned.
 *
 * Usage:
 *   const { signedUrl, loading, error } = useGcsSignedUrl({ url, version });
 */
const SIGNED_URL_PARAMETER = '?X-Goog-Algorithm'
export const useGcsSignedUrl = ({
  url,
  version,
}: {
  url: string | null
  version?: number
}): { signedUrl: string | null; loading: boolean; error: any; isGcsUrl: boolean } => {
  const needsSignedUrl =
    url && url.startsWith(GCS_PREFIX) && !url.includes(DOPPEL_VISION_ASSETS_BUCKETS)
  const alreadySignedUrl = url && url.includes(SIGNED_URL_PARAMETER)
  const [signedUrl, setSignedUrl] = useState<string | null>(null)
  const { bucketName, objectName } = needsSignedUrl
    ? getBucketNameAndObjectName(url)
    : { bucketName: '', objectName: '' }

  const { loading, error } = useGetGcsObjectQuery({
    variables: { bucket_name: bucketName, object_name: objectName },
    onCompleted: (data) =>
      setSignedUrl(
        data?.get_gcs_object?.object_info?.object_versions[version || 0]?.signed_url,
      ),
    onError: (_error) => setSignedUrl(null),
    skip: !needsSignedUrl || !bucketName || !objectName,
  })

  if (!needsSignedUrl) {
    return { signedUrl: null, loading: false, error: null, isGcsUrl: needsSignedUrl }
  }
  // If the url has already been signed, return the signed url (complete with otherwise appended parameters)
  if (alreadySignedUrl) {
    return { signedUrl: url, loading: false, error: null, isGcsUrl: needsSignedUrl }
  }

  return { signedUrl, loading, error, isGcsUrl: needsSignedUrl }
}
