import { Button, Flex, forwardRef } from '@chakra-ui/react'
import {
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_BUTTON_GREY,
  DOPPEL_OFFICIAL_BLUE,
  DOPPEL_BORDER_GREY,
} from '../../utils/style'

const DoppelIconButton = forwardRef(
  (
    {
      icon,
      ariaLabel,
      isInternal = false,
      isPrimaryColor = false,
      isTransparent = false,
      buttonSize = 8,
      ...props
    },
    ref,
  ) => {
    const bgColor = (() => {
      if (isTransparent) return 'transparent'
      else if (isInternal) return DOPPEL_INTERNAL_PURPLE
      else if (isPrimaryColor) return DOPPEL_OFFICIAL_BLUE
      return DOPPEL_BUTTON_GREY
    })()
    return (
      <Button
        aria-label={ariaLabel}
        bgColor={bgColor}
        border={isTransparent ? 'none' : `0.5px solid ${DOPPEL_BORDER_GREY}`}
        height={buttonSize}
        minHeight={buttonSize}
        minWidth={buttonSize}
        padding={0}
        ref={ref}
        width={buttonSize}
        {...props}
      >
        <Flex alignItems="center" h="100%" justifyContent="center" w="100%">
          {icon}
        </Flex>
      </Button>
    )
  },
)

export default DoppelIconButton
