import { Tab, Badge } from '@chakra-ui/react'
import {
  BUTTON_COLOR_PRIMARY,
  DOPPEL_DARK,
  DOPPEL_DARK_SECONDARY,
  DOPPEL_WHITE,
} from '../../utils/style'
import { HIGHEST_NUMBER_TO_SHOW_IN_TABS } from '@/pages/reports'

const formatCount = (count: number): string => {
  if (count >= HIGHEST_NUMBER_TO_SHOW_IN_TABS) {
    return `${HIGHEST_NUMBER_TO_SHOW_IN_TABS}+`
  }
  return count.toString()
}

type MainTabProps = {
  name: string
  count?: number
  height?: number
}

export const MainTab = ({ name, count, height = 40 }: MainTabProps) => {
  return (
    <Tab
      _selected={{ color: DOPPEL_DARK, bg: DOPPEL_WHITE }}
      bg={DOPPEL_DARK_SECONDARY}
      borderRadius={8}
      fontSize={14}
      height={`${height}px`}
      marginRight="16px"
      overflow="visible"
      padding="7px 8px"
      position="relative"
      whiteSpace="nowrap"
    >
      {name}

      {count > 0 && (
        <Badge
          alignItems="center"
          bg={BUTTON_COLOR_PRIMARY}
          borderRadius="4px"
          color="white"
          display="flex"
          fontSize="13px"
          fontWeight={600}
          height="18px"
          justifyContent="center"
          marginLeft="6px"
          minWidth="16px"
          padding="0 4px"
        >
          {formatCount(count)}
        </Badge>
      )}
    </Tab>
  )
}
