import moment from 'moment-timezone'

import { ProductType } from './constants'
import {
  DOPPEL_DNS_PARKED,
  DOPPEL_EMPTY_WEBSITE,
  DOPPEL_SCRAPING_IN_PROGRESS,
  DOPPEL_SCRAPING_BLOCKED,
} from '@/utils/image'
import { getFallbackScreenshotInfo } from '@/utils/reports/report_utils'

export const WEB2_SCREENSHOTS_BUCKET = 'report_screenshots'
export const BASE_SCREENSHOT_URL = 'https://storage.googleapis.com/report_screenshots'
export const UPLOADED_FILES_BUCKET = 'uploaded_report_files'
export const ENFORCEMENT_SCREENSHOTS_BUCKET = 'enforcement_screenshots'
export const RETRACTION_SCREENSHOTS_BUCKET = 'enforcement_retraction_screenshots'
export const BRAND_ASSETS_BUCKET = 'doppel_brand_assets'
export const GCS_PREFIX = 'https://storage.googleapis.com/'

export function getFlaggedPreviewImage(selectedSpoofReport) {
  const createdAtMoment = moment.utc(selectedSpoofReport?.created_at)
  const twoDaysAgo = moment().subtract(2, 'days')

  const fallbackScreenshotInfo = getFallbackScreenshotInfo(
    selectedSpoofReport,
    WEB2_SCREENSHOTS_BUCKET,
  )

  if (fallbackScreenshotInfo) {
    return fallbackScreenshotInfo.url
  } else if (selectedSpoofReport?.spoof_status == 'parked') {
    return DOPPEL_DNS_PARKED
  } else if (selectedSpoofReport?.spoof_status == 'down') {
    return DOPPEL_EMPTY_WEBSITE
  } else if (createdAtMoment.isValid() && createdAtMoment.isBefore(twoDaysAgo)) {
    return DOPPEL_SCRAPING_BLOCKED
  }
  return DOPPEL_SCRAPING_IN_PROGRESS
}

export function getFlaggedPreviewImageBoxSize(selectedSpoofReport) {
  if (selectedSpoofReport?.social_media_data || selectedSpoofReport?.mobile_app) {
    return { width: 'xs', height: 'xs' }
  }
  if (selectedSpoofReport?.platform?.product === ProductType.SUSPICIOUS_EMAILS) {
    return { width: '1152px', height: '650px' }
  }
  return { width: 'sm', height: 'sm' }
}
