import { useGcsSignedUrl } from '@/hooks/cloud_storage'
import { Image, Box } from '@chakra-ui/react'

export enum TablePreviewSize {
  REGULAR,
  LARGE,
}

export const previewSizeToPixels = {
  [TablePreviewSize.REGULAR]: 70,
  [TablePreviewSize.LARGE]: 85,
}

export const previewSizeToWidth = {
  [TablePreviewSize.LARGE]: 70,
}

const TablePreview = ({
  src,
  fallbackSrc = null,
  previewSize = TablePreviewSize.REGULAR,
  openOnClick = false,
  width = undefined,
}) => {
  const { signedUrl, isGcsUrl } = useGcsSignedUrl({ url: src })
  const { signedUrl: signedFallback, isGcsUrl: isGcsFallback } = useGcsSignedUrl({
    url: fallbackSrc,
  })
  if (!src) return <></>

  // const shouldSign =
  //   src.startsWith(GCS_PREFIX) && !src.includes(DOPPEL_VISION_ASSETS_BUCKETS)

  const url = isGcsUrl ? signedUrl : src
  const fallbackUrl = isGcsFallback ? signedFallback : fallbackSrc

  return (
    <Box
      onClick={() => openOnClick && window.open(url, '_blank', 'noreferrer')}
      width={width || previewSizeToPixels[previewSize]}
    >
      <Image
        boxShadow={'l'}
        boxSize={previewSizeToPixels[previewSize]}
        fallbackSrc={fallbackUrl}
        rounded={'md'}
        src={url}
        width={width}
      />
    </Box>
  )
}

export default TablePreview
