import { Flex, Icon, Heading, Button, Text } from '@chakra-ui/react'
import { MdSearch } from 'react-icons/md'
import NextLink from 'next/link'
import { DOPPEL_TEXT_GREY } from '@/utils/style'

export default function NotFoundPage() {
  return (
    <Flex align="center" direction="column" h="100vh" justify="center" w="100%">
      <Icon as={MdSearch} boxSize={16} />

      <Heading as="h1" fontSize={24} fontWeight={500} mt={4}>
        Page not found
      </Heading>

      <Text color={DOPPEL_TEXT_GREY} maxW={340} mt={2} textAlign="center">
        The page you&apos;re looking for doesn&apos;t exist, or the address may be
        wrong.
      </Text>

      <NextLink href="/">
        <Button mt={4} size="md">
          Back to Doppel Home
        </Button>
      </NextLink>
    </Flex>
  )
}
