import { Flex, Spinner, Tbody, Td, Tr } from '@chakra-ui/react'
import { DOPPEL_CLICKABLE_GREY, DOPPEL_DARK_SECONDARY } from '../../utils/style'
import { useColorModeValue } from '@chakra-ui/react'
import { CHECKBOX_COLUMN_ID } from '@/utils/table_utils'

const hoverableStyle = {
  cursor: 'pointer',
  transition: 'background-color 0.3s',
}

const TableBody = ({
  isLoading,
  page,
  bodyStyle,
  tableBodyProps,
  prepareRow,
  selectedRow,
  setSelectedObj,
}) => {
  const hoverBgColor = useColorModeValue(DOPPEL_CLICKABLE_GREY, DOPPEL_DARK_SECONDARY) // Light grey for light mode, darker grey for dark mode

  const rowStyle = {
    ...(setSelectedObj ? hoverableStyle : {}),
  }
  if (isLoading) {
    return (
      <Tbody>
        <Tr>
          <Td>
            <Flex align="center" height="50vh" justify="center" width="100vw">
              <Spinner />
            </Flex>
          </Td>
        </Tr>
      </Tbody>
    )
  }

  return (
    <Tbody {...tableBodyProps} style={{ textAlign: 'left' }}>
      {page.map((row, i) => {
        prepareRow(row)
        const onRowClick = (e) => {
          if (!setSelectedObj) return
          const target = e.target as HTMLInputElement
          if (
            target.nodeName == 'svg' ||
            target.nodeName == 'INPUT' ||
            target.children[0]?.nodeName == 'svg'
          ) {
            return
          }
          setSelectedObj(row.original)
        }
        return (
          <Tr
            key={row.id}
            {...row.getRowProps()}
            style={{
              fontSize: '10pt',
              textAlign: 'left',
              ...rowStyle,
            }}
            onMouseEnter={(e) => {
              if (setSelectedObj) {
                e.currentTarget.style.backgroundColor = hoverBgColor
              }
            }}
            onMouseLeave={(e) => {
              if (setSelectedObj && selectedRow !== row.id) {
                e.currentTarget.style.backgroundColor = ''
              }
            }}
          >
            {row.cells.map((cell) => (
              <Td
                key={cell.value}
                {...cell.getCellProps()}
                style={bodyStyle}
                onClick={cell.column.id === CHECKBOX_COLUMN_ID ? undefined : onRowClick}
              >
                {cell.render('Cell')}
              </Td>
            ))}
          </Tr>
        )
      })}
    </Tbody>
  )
}

export default TableBody
