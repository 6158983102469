import { MenuItem, Spinner, Input, useDisclosure, Text } from '@chakra-ui/react'
import React, { useState, useRef, useMemo } from 'react'
import { DOPPEL_INTERNAL_PURPLE } from '../../utils/style'
import BulkActionButton from './bulk_action_button'
import DoppelAlertDialog from '../shared/doppel_alert_dialog'
import {
  isSubtypeDefault,
  PLATFORM_SUBTYPE_SORTED_DROPDOWN_OPTIONS,
  PlatformSubtypeMetadata,
} from '@/utils/reports/platform_subtype_utils'

const makeMenuListItem = (subtype, onClick, showArchetype) => ({
  key: subtype.id,
  content: (
    <MenuItem onClick={onClick}>
      {subtype.name}

      {showArchetype && ` (${subtype.archetype})`}
    </MenuItem>
  ),
})

// janky refactored "hook" only for reusing in modal reroute platform subtype
export const useReroutePlatformSubtypeMenuListItems = (
  handlePlatformSubtypeSelect: (subtype) => void,
) => {
  const [searchQuery, setSearchQuery] = useState('')

  const filteredSubtypes = useMemo(() => {
    return PLATFORM_SUBTYPE_SORTED_DROPDOWN_OPTIONS.filter((subtype) =>
      subtype.name.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [searchQuery])

  const showArchetypeForSubtype = (subtype: PlatformSubtypeMetadata): boolean => {
    return !isSubtypeDefault(subtype)
  }

  const handleSearchChange = (event) => setSearchQuery(event.target.value)

  return [
    {
      key: 'searchInput',
      content: (
        <Input
          m={2}
          onChange={handleSearchChange}
          placeholder="Search platforms..."
          variant="filled"
          width="90%"
        />
      ),
    },
    ...filteredSubtypes.map((subtype) =>
      makeMenuListItem(
        subtype,
        () => handlePlatformSubtypeSelect(subtype),
        showArchetypeForSubtype(subtype),
      ),
    ),
  ]
}

export default function BulkReroutePlatformSubtypeButton({
  loading,
  selectedRows,
  updateAlertFunc,
}) {
  const [platformSubtypeToReroute, setPlatformSubtypeToReroute] = useState<any>()
  const cancelRef = useRef()

  const handlePlatformSubtypeSelect = (subtype) => {
    setPlatformSubtypeToReroute(subtype)
    onOpen()
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const confirmReroute = () => {
    if (platformSubtypeToReroute) {
      updateAlertFunc(selectedRows, {
        platformSubtype: platformSubtypeToReroute,
      })
      onClose()
    }
  }
  const menuListItems = useReroutePlatformSubtypeMenuListItems(
    handlePlatformSubtypeSelect,
  )

  return (
    <>
      <BulkActionButton
        bgColor={DOPPEL_INTERNAL_PURPLE}
        isLoading={loading}
        menuListItems={menuListItems}
      >
        {loading ? <Spinner size="xs" /> : 'Bulk Reroute Platform'}
      </BulkActionButton>

      <DoppelAlertDialog
        body={
          <>
            <Text>
              Are you sure you want to reroute the selected {selectedRows.length}{' '}
              alert(s) to the platform {platformSubtypeToReroute?.name}?
            </Text>
          </>
        }
        cancelRef={cancelRef}
        confirmAction={confirmReroute}
        header={`Bulk Reroute Platform: ${platformSubtypeToReroute?.name}`}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
