import { ProductType, ProductTypePath } from '@/generated/enums'
import { PATH_TO_PRODUCT_TYPE } from './reports/report_utils'

export const isUserAuthorizedForProductType = (
  productType: ProductType,
  userProducts: ProductType[],
  isRbacEnabled: boolean,
): boolean => {
  if (isRbacEnabled && userProducts != null) {
    return userProducts.includes(productType)
  }
  return true
}

export const isUserAuthorizedForPage = (
  pathname: string,
  userProducts: ProductType[],
  isRbacEnabled: boolean,
): boolean => {
  const formattedPathname = pathname.replace('/', '') as ProductTypePath
  if (
    isRbacEnabled &&
    userProducts !== null &&
    Object.values(ProductTypePath).includes(formattedPathname)
  ) {
    return userProducts.includes(PATH_TO_PRODUCT_TYPE[formattedPathname])
  }
  return true
}
