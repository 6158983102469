import { ProductType } from '@/generated/enums'
import { atom, PrimitiveAtom } from 'jotai'
import { UserPermissionsData, Role, Permission } from '@/types/permissions'

export const userPermissionsDataAtom = atom<UserPermissionsData | null>(
  null,
) as PrimitiveAtom<UserPermissionsData | null>

export const userRoleAtom = atom<Role | null>(null) as PrimitiveAtom<Role | null>
export const userPermissionsAtom = atom<Permission[] | null>(null) as PrimitiveAtom<
  Permission[] | null
>
export const userProductsAtom = atom<ProductType[] | null>(null) as PrimitiveAtom<
  ProductType[] | null
>
