import { AlertLinkRelationship, AlertLinkType } from '@/generated/enums'
import { GetAlertLinksByAlertIdQuery } from '@/generated/graphql'
import { getExternalReportId } from '@/utils/reports/report_utils'

type FullUrl = {
  id: string
  url: string
  unsanitizedUrl?: string
  screenshotUrl?: string
  siteHtmlUrl?: string
  faviconUrl?: string
} | null

type Organization = {
  id: string
  name: string
  abbr_name?: string
}

type Platform = {
  name: string
  product: string
}

type SpoofMatch = {
  fullUrl: FullUrl
}

type User = {
  name: string
  id: string
}

export type LinkedAlertDetails = {
  id: string
  alert_id: string
  external_id: number
  spoofMatches: SpoofMatch[]
  organization: Organization
  platform: Platform
  flagged_url: string
}

export type AlertLink = {
  id: string
  alertId1: LinkedAlertDetails
  alertId2: LinkedAlertDetails
  type: AlertLinkType
  relationship: AlertLinkRelationship
  updatedByUser?: User
  updatedAt?: string
  createdAt?: string
}

export const convertToInternalAlertLinks = (
  alertLinks: GetAlertLinksByAlertIdQuery['alert_links'],
): AlertLink[] => {
  const mapAlertDetails = (alert: any): LinkedAlertDetails => ({
    id: alert.id,
    alert_id: getExternalReportId(alert.organization.abbr_name, alert.external_id),
    external_id: alert.external_id,
    spoofMatches: alert.spoof_matches.map((match: any) => ({
      fullUrl: match.full_url
        ? {
            id: match.full_url.id,
            url: match.full_url.url,
            unsanitizedUrl: match.full_url.unsanitized_url,
            screenshotUrl: match.full_url.screenshot_url,
            siteHtmlUrl: match.full_url.site_html_url,
            faviconUrl: match.full_url.favicon_url,
          }
        : null,
    })),
    organization: {
      id: alert.organization.id,
      name: alert.organization.name,
      abbr_name: alert.organization.abbr_name,
    },
    platform: {
      name: alert.platform.name,
      product: alert.platform.product,
    },
    flagged_url: alert.flagged_url,
  })

  return alertLinks
    .filter((alertLink) => {
      // alert linking can be cross org which will not populate for clients, so need to filter out these rows
      return alertLink.alert_1 && alertLink.alert_2
    })
    .map((alertLink) => ({
      id: alertLink.id,
      alertId1: mapAlertDetails(alertLink.alert_1),
      alertId2: mapAlertDetails(alertLink.alert_2),
      type: alertLink.type as AlertLinkType,
      relationship: alertLink.relationship as AlertLinkRelationship,
      updatedByUser: {
        id: alertLink.user?.id,
        name: alertLink.user?.name,
      },
      updatedAt: alertLink.updated_at,
      createdAt: alertLink.created_at,
    }))
}
