import { HStack, Text, Tooltip } from '@chakra-ui/react'

import DoppelPrimaryButton from '../shared/doppel_primary_button'

const StatusChangeButton = ({
  text,
  statusTo,
  selectedRows,
  actionLoadingState,
  handleClick,
  hoverText = '',
  ...props
}) => {
  return (
    <Tooltip label={hoverText} hasArrow isDisabled={!hoverText}>
      <span style={{ cursor: 'pointer', position: 'relative' }}>
        <DoppelPrimaryButton
          disabled={selectedRows.length === 0}
          fontSize={12}
          h={'30px'}
          isLoading={actionLoadingState?.status === statusTo}
          onClick={() => handleClick()}
          {...props}
        >
          <HStack>
            <Text fontSize={11}>{'Bulk ' + text}</Text>
          </HStack>
        </DoppelPrimaryButton>
      </span>
    </Tooltip>
  )
}

export default StatusChangeButton
